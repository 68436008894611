import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { StoreContext } from '../data/storeContext';
import { apiGet, apiPost } from '../services/api';
import { formatReceiptsWithCompanies } from '../utils/formatReceipts';
import { useUser } from './useUser';

export function useInvoice() {
  const { setLoading, setError } = useContext(StoreContext);
  const { userLogout } = useUser();
  const queryClient = useQueryClient();

  async function fetchDataListInvoice(dateRanger) {
    setLoading(true);
    try {
      let hasMorePages = true;
      let allReceipts = [];
      let allCompanies = [];
      let page = 0;

      while (hasMorePages) {
        const { data } = await apiGet(
          `/Receipt?createdSince=${dateRanger?.dateInitial}&createdUntil=${dateRanger?.dateFinally}&page=${page}`,
        );

        const { receipts, companies, has_more_pages } = data;

        allReceipts = [...allReceipts, ...receipts];
        allCompanies = [...allCompanies, ...companies];
        hasMorePages = has_more_pages;
        page += 1;
      }

      const formattedData = formatReceiptsWithCompanies(
        allReceipts,
        allCompanies,
      );

      return formattedData.reverse();
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        setError({ order: 'Você não está autenticado' });
        userLogout();
      }
      if (status === 500) {
        setError({ order: 'Erro no servidor' });
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchReceiptKeyApprove(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/approve`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchReceiptKeyRefuse(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/refuse`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchReceiptKeyRefuseV2(key, message) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/refuse/v2`, message);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  async function fetchReceiptKeyCancel(key) {
    try {
      setError(false);

      await apiPost(`/receipt/${key}/cancel`);

      await queryClient.invalidateQueries(['InvoiceList']);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
    return true;
  }

  return {
    fetchDataListInvoice,
    fetchReceiptKeyApprove,
    fetchReceiptKeyRefuse,
    fetchReceiptKeyRefuseV2,
    fetchReceiptKeyCancel,
  };
}
