export const data = [
  {
    idBalanceType: 0,
    name: 'not_found',
    description: 'Indefinido',
    operation: '---',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 1,
    name: 'adding_balance_account',
    description: 'Depósito',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 2,
    name: 'outbound_transfer',
    description: 'Pagamento',
    operation: 'Debit',
    color: 'var(--gray-600)',
    background: 'var(--white)',
  },
  {
    idBalanceType: 3,
    name: 'outbound_payment',
    description: 'Pagamento de Titulos',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--white)',
  },
  {
    idBalanceType: 4,
    name: 'refund_transfer',
    description: 'Estorno ',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 5,
    name: 'refund_payment',
    description: 'Devolução de Pagamento de Titulos',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 6,
    name: 'outbound_validate',
    description: 'Validação de Dados',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--white)',
  },
  {
    idBalanceType: 7,
    name: 'outbound_monthly_fee',
    description: 'Desconto de Antecipação',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--white)',
  },
  {
    idBalanceType: 8,
    name: 'discount_monthly_fee',
    description: 'Desconto Mensalidade',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 9,
    name: 'antecipation',
    description: 'Antecipação',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 99,
    name: 'discount_monthly_fee',
    description: 'Aporte Credito',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 11,
    name: 'unlocked_funds',
    description: 'Liberação de fundos restringidos',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 12,
    name: 'debt_payment',
    description: 'Pagamento de débito',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 13,
    name: 'pix_refund',
    description: 'Estorno de Pix',
    operation: 'Debit',
    color: 'var(--red)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 14,
    name: 'balance_sync',
    description: 'Sincronização de saldo com celcoin',
    operation: 'Both',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 15,
    name: 'fee_collection',
    description: 'Coleta de Taxa',
    operation: 'Credit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 16,
    name: 'consignation_bill_refund',
    description: 'Estorno de Cobrança de Consignação',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 17,
    name: 'consignation_collection',
    description: 'Coleta de Consignação',
    operation: 'Credit',
    color: 'var(--green)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 18,
    name: 'consignation_refund',
    description: 'Estorno de Consignação',
    operation: 'Credit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 19,
    name: 'card',
    description: 'Uso do cartão',
    operation: 'Debit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 20,
    name: 'card_refund',
    description: 'Devolução de uso de cartão',
    operation: 'Credit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 21,
    name: 'linked_account_bill',
    description: 'Taxa de conta ativa',
    operation: 'Debit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
  {
    idBalanceType: 22,
    name: 'linked_account_bill_collection',
    description: 'Coleta de taxa de conta ativa',
    operation: 'Credit',
    color: 'var(--gray-600)',
    background: 'var(--gray-50)',
  },
].map((item) => ({ label: item.description, value: item.idBalanceType }));
