export class CurrencyFormatter {
  static formatMoney(value: number): string {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  static formatFee(value: number): string {
    return CurrencyFormatter.formatMoney(Math.abs(value));
  }
}
