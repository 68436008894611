import React, { useState } from 'react';
import styles from './styles.module.scss';
import Switch from 'react-switch';
import { memo } from 'react';
import { useUser } from '../../../../hooks/useUser';
import { apiGet, apiPutEditUser } from '../../../../services/api';
import { formatDocument } from '../../../../utils/formatDocument';
import ToolTip from '../../../../components/UI/ToolTip';
import Modal from '../Modal';
import { useCustomers } from '../../../../hooks/useCustomers';
import { sendLog } from '../../../../usecases/logs/logs';
import { LogActions, LogTypes } from '../../../../domain/emuns/LogActions';
import { useHistory } from 'react-router-dom';
import { useNotification } from '../../../../hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { Loader } from 'rsuite';
import { formatResponseUser } from '../../../../utils/formatResponseUser';
/**
 * Componente que exibe um cartão de informações de cliente e permite a edição de detalhes do cliente.
 *
 * Este componente exibe informações sobre um cliente, incluindo nome, documento, email, data de adição e status de ativação. Ele também fornece um interruptor para ativar ou desativar o cliente e oferece a opção de editar os detalhes do cliente.
 *
 * @component
 * @param {string} first_name - O primeiro nome do cliente.
 * @param {string} last_name - O sobrenome do cliente.
 * @param {string} document - O número do documento do cliente.
 * @param {number} salary - O salário do cliente.
 * @param {string} date_added - A data de adição do cliente.
 * @param {boolean} is_active - O status de ativação do cliente.
 * @param {string} telephone - O número de telefone do cliente.
 * @param {number} customer_id - O ID do cliente.
 * @param {string} email - O endereço de e-mail do cliente.
 * @param {string} register - O registro do cliente.
 * @param {function} setEdit - Uma função para definir os detalhes do cliente para edição.
 * @param {number} debit - O valor do débito do cliente.
 * @param {object} company - Informações da empresa associada ao cliente.
 * @param {string} name_profile - Nome do perfil do usuário
 * @returns {JSX.Element} Um cartão de informações do cliente.
 *
 * @example
 * // Exemplo de uso:
 * import { CardCustomers } from './CardCustomers';
 *
 * function MeuComponente() {
 *   return (
 *     <CardCustomers
 *       first_name="John"
 *       last_name="Doe"
 *       document="123456789"
 *       salary={5000}
 *       date_added="2023-09-30"
 *       is_active={true}
 *       telephone="123-456-7890"
 *       customer_id={1}
 *       email="john@example.com"
 *       register="ABCD1234"
 *       setEdit={handleEditCustomer}
 *       debit={0}
 *       company={companyData}
 *     />
 *   );
 * }
 */
const CardCustomers = ({
  first_name,
  last_name,
  document,
  salary,
  date_added,
  is_active,
  telephone,
  customer_id,
  email,
  register,
  setEdit,
  debit,
  company,
  profile,
  name,
  capabilities,
  additional_company_ids,
  name_profile,
}) => {
  const [checked, setChecked] = useState(is_active);
  const [modal, setModal] = useState(false);
  const { location } = useHistory();
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();

  const { setIsNewCustomer, loading, setError, setLoading } = useCustomers();
  const { user } = useUser();

  async function fetchCustomersEdit(isChecked) {
    setLoading({ customers: false, add: true, id: customer_id });

    try {
      const { data: dataUserExist } = await apiGet(
        `/Customer/document/${document.replace(/[^\d]/g, '')}`,
      );

      const { data } = await apiPutEditUser(
        `/customer`,
        {
          is_active: !isChecked,
          first_name,
          last_name,
          document,
          telephone,
          email,
        },
        dataUserExist?.company_id
          ? dataUserExist?.company_id
          : company.company_id,
      );
      const { is_active } = await data;
      await queryClient.invalidateQueries(['customerListData']);
      if (data) {
        addNotification(
          <>{data?.first_name}, alterado com sucesso</>,
          0,
          'Perfil',
          true,
        );
        await sendLog(
          is_active ? LogActions.ACTIVE_CUSTOMER : LogActions.DISABLED_CUSTOMER,
          LogTypes.SUCCESS,
          {
            customer_id: data.customer_id,
            payload: formatResponseUser(data),
            path: location.pathname,
          },
        );
      } else {
        addNotification(
          <>Error inesperado, atualize a pagina pra confirmar a alteração</>,
          2,
          'Perfil',
          true,
        );
        await sendLog(
          is_active ? LogActions.ACTIVE_CUSTOMER : LogActions.DISABLED_CUSTOMER,
          LogTypes.ERROR,
          {
            customer_id: dataUserExist.customer_id,
            payload: formatResponseUser(dataUserExist),
            path: location.pathname,
            error: 'Sem retorno de dados do usuário',
          },
        );
      }
    } catch (error) {
      setError({ login: 'Não foi possível editar ' });
      setChecked(checked);
    } finally {
      setLoading({ customers: false, add: false });
    }
  }

  function handleSelect() {
    if (debit === 0 || is_active === false) {
      handleFetchEdit();
    } else {
      setModal(true);
    }
  }

  function handleFetchEdit() {
    setChecked(!checked);
    fetchCustomersEdit(checked);
  }

  function handleEditCustomer() {
    let editPromise = new Promise((resolve) => {
      setIsNewCustomer(false);
      setEdit(false);
      resolve();
    });

    editPromise.then(() => {
      setEdit({
        first_name,
        last_name,
        document,
        salary,
        date_added,
        register,
        is_active,
        telephone,
        customer_id,
        email,
        profile,
        capabilities,
        additional_company_ids,
      });
      setIsNewCustomer(true);
    });
  }
  return (
    <>
      <ul
        className={`${
          [7].every((item) => user?.permission.includes(item))
            ? styles.container
            : styles.container_
        }`}
      >
        <li>
          <span onClick={() => handleEditCustomer()} className={styles.name}>
            {name}
          </span>
        </li>
        <li>
          <span className={styles.document}>{formatDocument(document)}</span>
        </li>
        <li>
          <span className={styles.email}>{email}</span>
        </li>
        <li>
          <span className={styles.date}>{date_added}</span>
        </li>
        <li>
          <span className={styles.date}>{name_profile}</span>
        </li>

        {loading?.id === customer_id ? (
          <li className={styles.loading}>
            <Loader />
          </li>
        ) : (
          <>
            {[0, 7].every((item) => user?.permission.includes(item)) && (
              <li className={styles.wrapper__Switch}>
                <ToolTip text={is_active ? 'Ativado' : 'Desativado'}>
                  <Switch
                    onChange={handleSelect}
                    checked={checked}
                    checkedIcon={true}
                    uncheckedIcon={true}
                    height={20}
                    width={47}
                    handleDiameter={14}
                    offColor="#e6e6e6"
                    onColor="#71AB69"
                    offHandleColor="#e63a0f"
                    onHandleColor="#ffffff"
                  />
                </ToolTip>
              </li>
            )}
          </>
        )}
      </ul>
      {modal && (
        <Modal
          modal={modal}
          setModal={setModal}
          handleFetchEdit={handleFetchEdit}
          amount={debit}
          name={`${first_name} ${last_name}`}
        />
      )}
    </>
  );
};

export default memo(CardCustomers);
