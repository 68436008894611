const secretKeyString = process.env.REACT_APP_SECRET_KEY;

export async function generateKeyUser(secretKeyString) {
  const enc = new TextEncoder();
  const keyMaterial = enc.encode(secretKeyString);

  const hash = await crypto.subtle.digest('SHA-256', keyMaterial);

  return crypto.subtle.importKey('raw', hash, 'AES-GCM', false, [
    'encrypt',
    'decrypt',
  ]);
}

export async function encryptData(data, secretKey) {
  const encodedData = new TextEncoder().encode(JSON.stringify(data));
  const iv = crypto.getRandomValues(new Uint8Array(12));

  const encrypted = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    secretKey,
    encodedData,
  );

  return {
    iv: Array.from(iv),
    data: Array.from(new Uint8Array(encrypted)),
  };
}

export async function decryptData(encryptedData, iv, secretKey) {
  const encryptedArray = new Uint8Array(encryptedData);
  const ivArray = new Uint8Array(iv);

  const decrypted = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: ivArray,
    },
    secretKey,
    encryptedArray,
  );

  const decodedData = new TextDecoder().decode(decrypted);
  return JSON.parse(decodedData);
}

// utils/localStorageUtils.js
export async function getValueFromLocalStorage(key, initialValue) {
  try {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) return false;
    if (storedValue) {
      const { iv, data } = JSON.parse(storedValue);
      const secretKey = await generateKeyUser(secretKeyString); // Certifique-se de que 'secretKeyString' está acessível ou passe como argumento
      const decryptedValue = await decryptData(data, iv, secretKey);
      return decryptedValue || initialValue;
    }

    return initialValue;
  } catch (error) {
    console.error(`Erro ao recuperar ${key} do armazenamento local:`, error);
    return initialValue;
  }
}
