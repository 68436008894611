import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown } from 'react-icons/bi';
import { v4 as uid } from 'uuid';
import {
  key_contract_agent,
  key_contract_comodato,
  key_contract_daily,
  key_contract_service,
} from '../../../config';
import { useUser } from '../../../hooks/useUser';
import { apiGet } from '../../../services/api';
import { IconClose, IconPlus } from '../Icons';
import { containsWord } from '../utils/containWord';
import FormGroup from './FormGroup';
import FormTextarea from './FormTextarea';
import styles from './styles.module.scss';
import TagInput from './TagInput';
import {
  ValidateAddCollaborator,
  ValidateAddCollaboratorAgent,
  ValidateAddCollaboratorDaily,
  ValidateAddCollaboratorServices,
} from './utils/addValidate';
const contractValidate = [
  { id: 0, text: '', validate: ValidateAddCollaborator },
  { id: 1, text: key_contract_agent, validate: ValidateAddCollaboratorAgent },
  { id: 2, text: key_contract_daily, validate: ValidateAddCollaboratorDaily },
  {
    id: 3,
    text: key_contract_service,
    validate: ValidateAddCollaboratorServices,
  },
  {
    id: 4,
    text: key_contract_comodato,
    validate: ValidateAddCollaborator,
  },
];
export function filterContracts(str) {
  const filteredContracts = contractValidate.find((contract) =>
    containsWord(contract.text.toLowerCase(), str),
  );

  if (!filteredContracts) {
    return { id: 0, text: '', validate: ValidateAddCollaborator };
  }

  return filteredContracts;
}

const AddUserForm = (props) => {
  const [clear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState();
  const [isRequired, setIsRequired] = useState(false);
  const [isContract, setIsContract] = useState(false);
  const [contractType, setContractType] = useState(contractValidate[0]);
  const [tags, setTags] = useState([]);
  const { user } = useUser();
  const permissionTags = [40].every((item) => user?.permission.includes(item));
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(contractType?.validate),
  });

  const contractIsType = watch('contract_type');

  useEffect(() => {
    setContractType(filterContracts(contractIsType));
  }, [contractIsType]);

  const onSubmit = (values) => {
    if (contractType?.id !== 1) {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
    } else {
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
      delete values.COMODATO;
    }
    if (contractType?.id !== 2) {
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DO_SERVICO;
      delete values.COMODATO;
    }
    if (contractType?.id !== 3) {
      delete values.VALOR_DO_SERVICO;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;

      delete values.COMODATO;
    }
    if (contractType?.id !== 4) {
      delete values.COMODATO;
    } else {
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
    }
    if (!values?.is_contract_required) {
      delete values?.is_contract_required;
      delete values.contract_type;
      delete values.NOME_DO_AGENCIADOR;
      delete values.DOCUMENTO_DO_AGENCIADOR;
      delete values.VALOR_DA_DIARIA;
      delete values.VALOR_DA_DIARIA_AJUDANTE;
      delete values.VALOR_DO_SERVICO;
      delete values.COMODATO;
    }

    props.addUser({ ...values, ...(tags.length > 0 && { tags }) });
  };
  function handlerIsContract(value) {
    setIsContract(value);
    setIsRequired(value);
  }

  const handlerCancel = () => {
    props.setType(false);
  };
  async function fetchGetListContract() {
    setLoading(true);
    try {
      const { data } = await apiGet(`/Contract/template/list`);
      setContract(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchGetListContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={styles['form-container']}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.wrapper_form}>
        <div className={styles.block_form}>
          <FormGroup
            name="name"
            label="Nome:"
            type="text"
            placeholder="Nome completo"
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            typeMask={0}
          />
          <FormGroup
            name="cpf"
            label="CPF:"
            type="text"
            placeholder="999.999.999-99"
            disabled={false}
            register={register}
            errors={errors || { document: 'Documento inválido' }}
            mask={['999.999.999-99']}
            setValue={setValue}
            clean={clear}
            typeMask={1}
          />
          <FormGroup
            name="cnpj"
            label="CNPJ:"
            type="text"
            placeholder="99.999.999/9999-99"
            disabled={false}
            register={register}
            errors={errors || { document: 'Documento inválido' }}
            mask={['99.999.999/9999-99']}
            setValue={setValue}
            clean={clear}
            typeMask={1}
          />
          <FormTextarea
            name="description"
            label="Descrição:"
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            clean={clear}
          />
        </div>
        <div className={styles.block_check}>
          {permissionTags && <TagInput tags={tags} setTags={setTags} />}
          <FormGroup
            name="email"
            label="E-mail:"
            type="email"
            placeholder="exemple@mail.com"
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            typeMask={0}
          />
          <FormGroup
            name="phone_number"
            label="Celular:"
            type="tel"
            placeholder="(99) 99999-9999"
            disabled={false}
            register={register}
            errors={errors}
            setValue={setValue}
            mask={'(99) 99999-9999'}
            clean={clear}
            typeMask={1}
          />

          <div className={styles.wrapper_form_}>
            <div className={styles.radio_type}>
              <label className={styles.label} for="is_contract_required">
                A assinatura do contrato é obrigatória?
              </label>
              <br />
              <div className={styles.wrapper_radio}>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-true"
                    name="is_contract_required"
                    value={'true'}
                    checked={isContract === true}
                    onClick={() => handlerIsContract(true)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />
                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-true"
                  >
                    sim
                  </label>
                </p>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-false"
                    name="is_contract_required"
                    value={'false'}
                    checked={isContract === false}
                    onClick={() => handlerIsContract(false)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />

                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-false"
                  >
                    não
                  </label>
                </p>
              </div>
              {errors.is_contract_required && (
                <p className={styles.errorText}>
                  {errors.is_contract_required.message}
                </p>
              )}
              {isRequired === true && (
                <div className={styles.select_type}>
                  <div className={styles.select}>
                    <select
                      name="contract_type"
                      {...register('contract_type')}
                      disabled={loading}
                      value={watch('contract_type')}
                    >
                      <option value="0">
                        {loading ? 'Carregando ...' : ' Selecione o contrato:'}
                      </option>
                      {contract?.map((type) => (
                        <option key={uid()} value={type.contract_type}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    <BiChevronDown color="var(--primary-700)" size={'2rem'} />
                  </div>

                  {errors.type && (
                    <p className={styles.errorText}>{errors.type.message}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          {contractType?.id === 1 && isContract && (
            <>
              <FormGroup
                name="NOME_DO_AGENCIADOR"
                label="NOME DO AGENCIADOR:"
                type="text"
                placeholder="Nome completo"
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <FormGroup
                name="DOCUMENTO_DO_AGENCIADOR"
                label="DOCUMENTO DO AGENCIADOR (CPF ou CNPJ):"
                type="text"
                placeholder="99.999.999/9999-99"
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={['999.999.999-99', '99.999.999/9999-99']}
                setValue={setValue}
                clean={clear}
                typeMask={1}
              />
            </>
          )}
          {contractType?.id === 2 && isContract && (
            <>
              <FormGroup
                name="VALOR_DA_DIARIA"
                label="Valor da diária (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                setValue={setValue}
                clean={clear}
                typeMask={2}
              />
              <FormGroup
                name="VALOR_DA_DIARIA_AJUDANTE"
                label="Valor da diária ajudante (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                setValue={setValue}
                clean={clear}
                typeMask={2}
              />
            </>
          )}
          {contractType?.id === 3 && isContract && (
            <>
              <FormGroup
                name="VALOR_DO_SERVICO"
                label="Valor do Serviço (R$):"
                type="text"
                placeholder="9.999,99"
                disabled={false}
                register={register}
                errors={errors}
                currencyType={true}
                mask={[
                  '9,99',
                  '99,99',
                  '999,99',
                  '9.999,99',
                  '99.999,99',
                  `999.999,99`,
                ]}
                setValue={setValue}
                clean={clear}
                typeMask={2}
              />
            </>
          )}
          {contractType?.id === 4 && isContract && (
            <>
              <FormGroup
                name="COMODATO"
                label="Certificado de propriedade Nº"
                type="text"
                placeholder="9999 9999 9999 9999 9999 9999 9999"
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
                clean={clear}
                typeMask={3}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.wrapper_button}>
        <button
          type="button"
          className={styles.button_canceled}
          onClick={handlerCancel}
        >
          <IconClose /> Cancelar
        </button>
        <button className={styles.button_save} type="submit">
          <IconPlus size="1rem" /> Adicionar a
        </button>
      </div>
    </form>
  );
};

export default AddUserForm;
