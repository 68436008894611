import { useEffect, useRef, useState } from 'react';
import { Details } from '../../components/Details';

import axios from 'axios';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import ButtonGoBack from '../../components/ButtonGoBack';
import { LoadingBall } from '../../components/UI/LoadingBall';
import Status from '../../components/UI/Status';
import { useCollaboratorQuery } from '../../hooks/useQuery';
import { formatDocument } from '../../utils/formatDocument';
import styles from './styles.module.scss';
//icons
import { BiCheck } from 'react-icons/bi';
import { Loader } from 'rsuite';
import {
  IconAlertContract,
  IconCalendar,
  IconDocument,
  IconDocumentPerson,
  IconEmail,
  IconEyes,
  IconKey,
  IconPhone,
  IconStatus,
} from '../../components/Details/Icons';
import { useNotification } from '../../hooks/useNotifications';
import { useTags } from '../../hooks/useTags';
import { useUser } from '../../hooks/useUser';
import { apiDelete, apiPut, getApiUrl, getHeaders } from '../../services/api';
import { formatStatusOnboarding } from '../../utils/formatCollaborator';
import { formatDate } from '../../utils/formatDate';
import { formatContractName } from '../../utils/formatName';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { IconClose, IconTags } from '../Collaborators/Components/TagInput/icon';
import { DetailsEdit } from './Components/DetailsEdit';
import { IconUser } from './Components/DetailsEdit/Icons';
import Image from './Components/Image';
import ModalActive from './Components/ModalActive';
import ModalDeactivate from './Components/ModalDeactivate';
import ModalDeactivateOnboarding from './Components/ModalDeactivateOnboarding';

/**
 * Um componente React que exibe uma imagem com um carregamento de imagem padrão
 * enquanto a imagem da URL especificada está sendo carregada.
 *
 * @param {object} props - As propriedades do componente.
 * @param {string} props.src - A URL da imagem a ser carregada.
 * @param {string} props.alt - O texto alternativo da imagem.
 * @returns {JSX.Element} O componente de imagem.
 */

const Driver = () => {
  const { id } = useParams();
  const location = useLocation();

  const { status, is_active } = location.state || {};

  const { user: userPermission, customerListData } = useUser();
  const { removeTag } = useTags();
  const [loading, setLoading] = useState(false);
  const [modalDeactivate, setModalDeactivate] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);

  const queryClient = useQueryClient();

  const [menu, setMenu] = useState(false);

  const { addNotification } = useNotification();
  const permissionTags = [40].every((item) =>
    userPermission?.permission.includes(item),
  );

  const { isFetching, data: user } = useCollaboratorQuery(id);

  const file =
    user?.additional_data_json !== false
      ? user?.additional_data_json.find((item) => item.key === 'Arquivo')?.value
      : false;

  if (user?.additional_data_json) {
    user.additional_data_json = user.additional_data_json.filter(
      (item) => item.key !== 'Arquivo',
    );
  }

  const find_author =
    customerListData.length && user?.customer_id
      ? customerListData.find((item) => item?.customer_id === user?.customer_id)
      : false;

  const author = find_author
    ? {
        name: `${find_author.first_name} ${find_author?.last_name}`,
        email: find_author.email,
        document: formatDocument(find_author?.document),
        customer_id: find_author.customer_id,
        file,
      }
    : false;

  const scrollRef = useRef(null);

  async function fetchDeleteLinkCustomer(document) {
    setLoading(true);
    try {
      const { data } = await apiDelete(`/Company/Link/Customer/${document}`);

      if (data?.error) {
        data?.error.code === 9 &&
          addNotification(
            <>Não foi possível desativar colaborador</>,
            2,
            '',
            true,
          );
      } else {
        await queryClient.getQueryData(['ConsignableCollaborator']);

        addNotification(<>Colaborador desativado com sucesso</>, 0, '', true);
      }
      await queryClient.invalidateQueries(['ConsignableCollaborator']);
    } catch (error) {
      addNotification(<>Não foi possível desativar colaborador</>, 2, '', true);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPutLinkCustomer(document) {
    setLoading(true);
    try {
      const { data } = await apiPut(`/Company/Link/Customer/${document}`);

      if (data?.error) {
        data?.error.code === 9 &&
          addNotification(
            <>Não foi possível ativar colaborador</>,
            2,
            '',
            true,
          );
      } else {
        await queryClient.getQueryData(['ConsignableCollaborator']);

        addNotification(<>Colaborador Ativado com sucesso</>, 0, '', true);
      }
      await queryClient.invalidateQueries(['ConsignableCollaborator']);
    } catch (error) {
      addNotification(<>Não foi possível ativar colaborador</>, 2, '', true);
    } finally {
      setLoading(false);
    }
  }
  async function fetchGetDeleteOnboarding(document) {
    setLoading(true);
    try {
      const url = getApiUrl(`/Onboarding/${document}`);
      const options = {
        headers: getHeaders(),
      };
      const { data } = await axios.delete(url, options);

      if (data?.error) {
        data?.error.code === 9 &&
          addNotification(
            <>Não foi possível cancelar onboarding do colaborador</>,
            2,
          );
      } else {
        await queryClient.getQueryData(['ConsignableCollaborator']);

        addNotification(
          <>Onboarding de Colaborador cancelado com sucesso</>,
          0,
          '',
          true,
        );
      }
      await queryClient.invalidateQueries(['ConsignableCollaborator']);
    } catch (error) {
      addNotification(
        <>Não foi possível cancelar cadastro do colaborador</>,
        2,
        '',
        true,
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleReload() {
    try {
      setLoading(true);
      await queryClient.invalidateQueries(['keyCollaborator']);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  const handleUpdateTagsSelect = async (tag) => {
    setLoading(true);
    try {
      const result = await removeTag([tag], [id]);
      if (result) {
        await handleReload();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const menuItems = [
      { key: 'addresses', condition: user?.addresses?.length },
      { key: 'documents', condition: user?.documents?.length > 0 },
      {
        key: 'additional_data_json',
        condition: user?.additional_data_json?.length > 0,
      },
      {
        key: 'vehicles',
        condition:
          user?.documents?.length > 0 &&
          user?.documents.some((document) => document?.type?.text === 'CRLV'),
      },
      { key: 'author', condition: author },
      { key: 'history', condition: user?.history_data?.length > 0 },
    ];

    const isMenu = menuItems
      .filter((item) => item.condition)
      .map((item) => item.key);
    setMenu(isMenu[0] ?? 'addresses');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (scrollRef.current) {
      const elementRect = scrollRef.current.getBoundingClientRect();
      const marginTop =
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Calcula 5rem em pixels
      const scrollTop = window.scrollY + elementRect.top - marginTop;

      window.scrollTo({ top: scrollTop, behavior: 'smooth', block: 'start' });
    }
  }, [id]);

  if (!user && isFetching) {
    return (
      <div className={styles.container_loading}>
        <LoadingBall />
      </div>
    );
  } else {
    return (
      <div className={styles.container} ref={scrollRef}>
        {user && (
          <>
            <ModalDeactivate
              setModal={setModalDeactivate}
              modal={modalDeactivate}
              fetchGetOnboardingContract={fetchDeleteLinkCustomer}
              user={user}
            />
            <ModalActive
              setModal={setModalActive}
              modal={modalActive}
              fetchGetOnboardingContract={fetchPutLinkCustomer}
              user={user}
            />
            <ModalDeactivateOnboarding
              setModal={setModalCancel}
              modal={modalCancel}
              fetchGetOnboardingContract={fetchGetDeleteOnboarding}
              user={user}
            />
          </>
        )}
        <div className={styles.header_style} style={{ gap: '20px' }}>
          <ButtonGoBack backRoute={'/menu/contas'} />
          {([43].every((item) => userPermission?.permission.includes(item)) &&
            is_active === 33) ||
            user?.is_active === true ||
            (user?.customer?.is_active === true && (
              <button
                className={styles.button__}
                onClick={() => setModalDeactivate(true)}
              >
                <MdRemoveCircleOutline size={'1.2rem'} color="var(--white)" />
                Desativar
              </button>
            ))}
          {([40].every((item) => userPermission?.permission.includes(item)) &&
            is_active === 9) ||
            user?.is_active === false ||
            (user?.customer?.is_active === false && (
              <p className={styles.wrapper_button}>
                <button
                  className={styles.button__}
                  onClick={() => setModalActive(true)}
                >
                  <BiCheck size={'1.5rem'} color="var(--white)" />
                  Ativar
                </button>
              </p>
            ))}
          {[51].every((item) => userPermission?.permission.includes(item)) &&
            [1, 7, 6].includes(user?.status?.idOnboardingStatus) && (
              <p className={styles.wrapper_button}>
                <button
                  className={styles.button__}
                  onClick={() => setModalCancel(true)}
                >
                  <MdRemoveCircleOutline size={'1.2rem'} color="var(--white)" />
                  Cancelar
                </button>
              </p>
            )}
        </div>
        <div className={styles.driverInfo}>
          {user && (
            <div className={styles.personalInfoUser}>
              <header>
                <p className={styles.title_style}>
                  <span>{user.name}</span>
                </p>
              </header>

              <div className={styles.box}>
                <div className={styles.items_self}>
                  <Image src={user.selfie_url} alt="document" self={true} />
                </div>
                <div className={styles.container_info}>
                  <div className={styles.box_left}>
                    {user?.customer?.social_name && (
                      <Details
                        Icon={IconUser}
                        title={'Nome Social:'}
                        value={formatDocument(user?.customer?.social_name)}
                        border={false}
                      />
                    )}
                    {user?.cpf && (
                      <Details
                        Icon={IconDocumentPerson}
                        title={'CPF:'}
                        value={formatDocument(user?.cpf)}
                        copy={true}
                        border={false}
                      />
                    )}
                    {user?.cnpj && (
                      <Details
                        Icon={IconDocumentPerson}
                        title={'CNPJ:'}
                        value={formatDocument(user?.cnpj)}
                        copy={true}
                        border={false}
                      />
                    )}
                    {user?.phone_number && (
                      <Details
                        Icon={IconPhone}
                        title={'Celular:'}
                        value={formatPhoneNumber(user?.phone_number)}
                        border={false}
                      />
                    )}
                    {user?.email && (
                      <Details
                        Icon={IconEmail}
                        title={'E-mail:'}
                        value={user?.email}
                        border={false}
                      />
                    )}
                    {user?.onboarding_key && (
                      <Details
                        Icon={IconKey}
                        title={'Chave:'}
                        value={user?.onboarding_key}
                        copy={true}
                        border={false}
                      />
                    )}
                    {user?.status && (
                      <Details
                        Icon={IconStatus}
                        title={'Status:'}
                        value={status?.text || user?.status?.text || '---'}
                        border={false}
                      />
                    )}
                    {user?.status?.description && (
                      <Details
                        Icon={IconDocumentPerson}
                        title={'Descrição do Status:'}
                        value={
                          user?.reproval_reasons || user?.status?.description
                        }
                        description={true}
                        border={false}
                      />
                    )}
                  </div>
                  <div className={styles.box_right}>
                    {user?.date_added && (
                      <Details
                        Icon={IconCalendar}
                        title={'Data de Cadastro:'}
                        value={user?.date_added}
                        border={false}
                      />
                    )}
                    {user?.created_at_linked && (
                      <Details
                        Icon={IconCalendar}
                        title={'Data de Aprovação:'}
                        value={user?.created_at_linked}
                        border={false}
                      />
                    )}
                    {user?.unbind_at && (
                      <Details
                        Icon={IconCalendar}
                        title={'Ultima Atualização:'}
                        value={user?.unbind_at}
                        border={false}
                      />
                    )}
                    {user?.is_contract_required && (
                      <Details
                        Icon={IconDocument}
                        title={'Contrato obrigatório:'}
                        value={user?.is_contract_required ? 'SIM' : 'NÃO'}
                        border={false}
                      />
                    )}
                    {user?.is_contract_required && (
                      <Details
                        Icon={IconStatus}
                        title={'Tipo:'}
                        value={user?.type?.text}
                        border={false}
                      />
                    )}
                    {permissionTags && (
                      <>
                        {user?.tags?.length > 0 ? (
                          <DetailsEdit
                            Icon={() => (
                              <IconTags size="1.2rem" color="#686868" />
                            )}
                            title={'Tag:'}
                            isLoading={loading}
                            tags={user?.tags}
                            Context={() => (
                              <div className={styles.tags}>
                                {user?.tags?.length > 0 ? (
                                  <>
                                    {loading ||
                                      user?.tags?.map((item, index) => (
                                        <strong key={index}>
                                          {item}
                                          {permissionTags && (
                                            <button
                                              onClick={() =>
                                                handleUpdateTagsSelect(item)
                                              }
                                            >
                                              <IconClose />
                                            </button>
                                          )}
                                        </strong>
                                      ))}
                                  </>
                                ) : (
                                  <>{loading && <Loader />}</>
                                )}
                              </div>
                            )}
                            description={true}
                            border={false}
                            handleReload={handleReload}
                            id={user?.onboarding_key}
                          />
                        ) : (
                          <DetailsEdit
                            Icon={() => (
                              <IconTags size="1.2rem" color="#686868" />
                            )}
                            title={'Tag:'}
                            isLoading={loading}
                            tags={[]}
                            Context={() => <></>}
                            description={true}
                            border={false}
                            handleReload={handleReload}
                            id={user?.onboarding_key}
                          />
                        )}
                      </>
                    )}
                    {user?.is_contract_required && (
                      <>
                        {user?.contracts?.length > 0 ? (
                          <Details
                            Icon={() => (
                              <IconEyes
                                width="1.325rem"
                                height="1.575rem"
                                color="#686868"
                              />
                            )}
                            title={'Contrato:'}
                            isLoading={loading}
                            tags={user?.contracts}
                            Context={() => (
                              <div className={styles.tags}>
                                {user?.contracts.length > 0 ? (
                                  <>
                                    {loading ||
                                      user?.contracts.map((_, index) => (
                                        <strong key={index}>
                                          {index === 0
                                            ? !!user?.contract_name
                                              ? formatContractName(
                                                  user?.contract_name,
                                                  50,
                                                )
                                              : `Contrato`
                                            : `Anexo ${index}`}
                                          <Link
                                            to={`/contract/${id}/${index + 1}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <IconEyes
                                              width="1.025rem"
                                              height="1.275rem"
                                              color="#686868"
                                            />
                                          </Link>
                                        </strong>
                                      ))}
                                  </>
                                ) : (
                                  <>{loading && <Loader />}</>
                                )}
                              </div>
                            )}
                            description={true}
                            border={false}
                            handleReload={handleReload}
                            id={user?.onboarding_key}
                          />
                        ) : (
                          <Details
                            Icon={IconAlertContract}
                            title={'Contrato não Assinado:'}
                            value={'Pendente'}
                            border={false}
                          />
                        )}
                      </>
                    )}
                    {user?.parent_key && (
                      <Details
                        Icon={IconAlertContract}
                        title={'Cadastro de nova tentativa: SIM'}
                        Context={() => (
                          <>
                            <Link to={`/menu/collaborator/${user?.parent_key}`}>
                              Ver Cadastro Antigo
                            </Link>
                          </>
                        )}
                        description={true}
                        border={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.menu}>
            <div className={styles.header}>
              {user?.addresses?.length > 0 && (
                <button
                  className={
                    menu === 'addresses' ? styles.button_ : styles.button
                  }
                  onClick={() => setMenu('addresses')}
                >
                  Endereços
                </button>
              )}
              {user?.documents?.length > 0 && (
                <button
                  className={
                    menu === 'documents' ? styles.button_ : styles.button
                  }
                  onClick={() => setMenu('documents')}
                >
                  Documentos
                </button>
              )}
              {user?.additional_data_json?.length > 0 && (
                <button
                  className={
                    menu === 'additional_data_json'
                      ? styles.button_
                      : styles.button
                  }
                  onClick={() => setMenu('additional_data_json')}
                >
                  Dados adicionais
                </button>
              )}
              {user?.documents?.length > 0 &&
                user?.documents.find(
                  (document) => document?.type?.text === 'CRLV',
                ) && (
                  <button
                    className={
                      menu === 'vehicles' ? styles.button_ : styles.button
                    }
                    onClick={() => setMenu('vehicles')}
                  >
                    Veículos
                  </button>
                )}
              {author && (
                <button
                  className={menu === 'author' ? styles.button_ : styles.button}
                  onClick={() => setMenu('author')}
                >
                  Auditoria
                </button>
              )}
              {user?.history_data?.length > 0 && (
                <button
                  className={
                    menu === 'history' ? styles.button_ : styles.button
                  }
                  onClick={() => setMenu('history')}
                >
                  Histórico
                </button>
              )}
            </div>

            <main>
              {menu === 'addresses' && user?.addresses && (
                <div className={styles.personalInfo}>
                  {user?.addresses.map((address, index) => (
                    <div key={index}>
                      <Details
                        title={'Rua:'}
                        value={address?.street}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      <Details
                        title={'Número:'}
                        value={address?.street_number}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      <Details
                        title={'Bairro:'}
                        value={address?.neighborhood}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      <Details
                        title={'CEP:'}
                        value={address?.postal_code}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      <Details
                        title={'Cidade:'}
                        value={address?.city}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      <Details
                        title={'Estado:'}
                        value={address?.federative_unit}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                      {address?.complement && (
                        <Details
                          title={'Complemento:'}
                          value={address?.complement}
                          description={true}
                          border={false}
                          maxWidth="60rem"
                          padding="0.39rem 0.89rem"
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {menu === 'documents' && user?.documents && (
                <div className={styles.documentInfo}>
                  {user?.documents
                    .filter((document) => document?.type?.text !== 'CRLV')
                    .map((document, index) => (
                      <div key={index}>
                        <p className={styles.item_register}>
                          <Details
                            Icon={IconDocument}
                            title={'Registro:'}
                            value={document.type.text}
                            description={false}
                            border={false}
                            padding="0.39rem 0.89rem"
                          />
                        </p>
                        <p className={styles.items_img}>
                          <Image
                            src={document.front_image_url}
                            alt="documento_frente"
                            documentType={user?.cpf}
                            downloadName={user?.name}
                          />
                          <Image
                            src={document.back_image_url}
                            alt="documento_trás"
                            documentType={user?.cpf}
                            downloadName={user?.name}
                          />
                        </p>
                      </div>
                    ))}
                </div>
              )}
              {menu === 'vehicles' &&
                user?.documents.find(
                  (document) => document?.type?.text === 'CRLV',
                ) && (
                  <div className={styles.vehicleInfo}>
                    {user?.documents
                      .filter((document) => document?.type?.text === 'CRLV')
                      .map(
                        (document, index) =>
                          index === 0 && (
                            <>
                              <div>
                                <p className={styles.item_register}>
                                  <Details
                                    Icon={IconDocument}
                                    title={'Registro:'}
                                    value={document.type.text}
                                    description={false}
                                    border={false}
                                    padding="0.39rem 0.89rem"
                                  />
                                </p>
                                <p className={styles.items_}>
                                  <Image
                                    src={document.front_image_url}
                                    alt="documento_frente_CRLV"
                                    documentType={user?.cpf}
                                    downloadName={user?.name}
                                  />
                                </p>
                              </div>
                              <div className={styles.vehicle_box_details}>
                                {user?.vehicle &&
                                  user?.vehicle.map(({ index, key, value }) => (
                                    <Details
                                      title={`${key}:`}
                                      value={value}
                                      //description={true}
                                      border={true}
                                      padding="0.39rem 0.89rem"
                                    />
                                  ))}
                              </div>
                            </>
                          ),
                      )}
                  </div>
                )}
              {menu === 'additional_data_json' &&
                user?.additional_data_json && (
                  <div className={styles.documentInfo}>
                    <div className={styles.additional_data_json}>
                      {user?.additional_data_json.map(
                        ({ index, key, value }) => (
                          <Details
                            title={`${key}:`}
                            value={value}
                            description={true}
                            border={false}
                            maxWidth="60rem"
                            padding="0.39rem 0.89rem"
                          />
                        ),
                      )}
                    </div>
                  </div>
                )}
              {menu === 'author' && author && (
                <div className={styles.documentInfo}>
                  <div className={styles.additional_data_json}>
                    {author?.name && (
                      <Details
                        title={'Nome:'}
                        value={author?.name}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                    )}
                    {author?.document && (
                      <Details
                        title={'Documento:'}
                        value={author?.document}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                        copy={true}
                      />
                    )}
                    {author?.email && (
                      <Details
                        title={'E-mail:'}
                        value={author?.email}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                        copy={true}
                      />
                    )}
                    {file && (
                      <Details
                        title={'Arquivo:'}
                        value={file}
                        description={true}
                        border={false}
                        maxWidth="60rem"
                        padding="0.39rem 0.89rem"
                      />
                    )}
                  </div>
                </div>
              )}

              {menu === 'history' && user?.history_data?.length > 0 && (
                <div className={styles.documentInfo}>
                  <div className={styles.additional_data_json}>
                    {user?.history_data.map(
                      ({
                        index,
                        key,
                        value,
                        status,
                        selfie_url,
                        created_at,
                        document,
                        cpf,
                        cnpj,
                        id,
                      }) => (
                        <>
                          <Details
                            title={`Chave:`}
                            value={key}
                            icon={IconKey}
                            description={false}
                            border={true}
                            maxWidth="60rem"
                            padding="0.39rem 0.89rem 0.39rem 0.89rem"
                            copy={true}
                          />
                          <Details
                            description={true}
                            border={false}
                            Context={() => (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <Image
                                  src={selfie_url}
                                  alt="document"
                                  self={true}
                                  preview={true}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    flexDirection: 'column',
                                    gap: '0.8rem',
                                  }}
                                >
                                  <strong>
                                    Data de Cadastro:{' '}
                                    {formatDate(
                                      created_at,
                                      'dd/MM/yyyy - HH:mm:ss',
                                    )}{' '}
                                  </strong>

                                  {document && (
                                    <strong>
                                      Documento:{' '}
                                      {document && formatDocument(document)}
                                    </strong>
                                  )}

                                  {cpf && (
                                    <strong>CPF: {formatDocument(cpf)}</strong>
                                  )}

                                  {cnpj && (
                                    <strong>
                                      CNPJ: {formatDocument(cnpj)}
                                    </strong>
                                  )}
                                  {key !== user?.key && (
                                    <Link to={`/menu/collaborator/${key}`}>
                                      Ver Cadastro
                                    </Link>
                                  )}
                                  <strong
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '0.5rem',
                                    }}
                                  >
                                    Status:{' '}
                                    <Status
                                      status={formatStatusOnboarding(
                                        Number(status),
                                      )}
                                    />
                                  </strong>
                                </div>
                              </div>
                            )}
                            maxWidth="60rem"
                            padding="0.39rem 0rem 2rem 0rem  "
                          />
                        </>
                      ),
                    )}
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    );
  }
};
export default Driver;
