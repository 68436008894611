import { Text, View } from '@react-pdf/renderer';

import { formatMoney } from '../../../../../../utils/formatMoney';
import { styles } from '../styles';
import { CurrencyFormatter } from '../../utils/fotmatMoneyPDF';

export function ExtractTemplateContent({ data }) {
  return (
    <View style={styles.column}>
      <View style={styles.row}>
        <Text style={styles.title}>Transações</Text>
      </View>
      {data.length
        ? data.map((item, i) => (
            <View style={styles.section} key={i}>
              <View style={styles.sectionTitle}>
                <Text style={styles.subTitle}>{item.date}</Text>
                <View style={styles.row}>
                  <Text style={styles.textBlue}>Saldo do dia: </Text>
                  <Text style={styles.boldBlue}>{formatMoney(item.after)}</Text>
                </View>
              </View>
              <View style={styles.transactionsHeader}>
                <View style={styles.line} key={i}>
                  <Text style={styles.outText}>TIPO</Text>
                  <Text style={styles.colName}>NOME</Text>
                  <Text style={styles.col1}>DOCUMENTO</Text>
                  <Text style={styles.out}>VALOR</Text>
                  <Text style={styles.out}>TAXA</Text>
                </View>
              </View>
              <View style={styles.transactionsList}>
                {item.transactions.length
                  ? item.transactions.map((data, i) => (
                      <View style={styles.line} key={i}>
                        {data?.after - data?.before > 0 ? (
                          <Text style={styles.inText}>
                            {data?.title?.description ||
                              data?.movementType?.label ||
                              '---'}
                          </Text>
                        ) : (
                          <Text style={styles.outText}>
                            {data?.movementType?.label || '---'}
                          </Text>
                        )}
                        <Text style={styles.colName}>
                          {data?.name || '---'}
                        </Text>
                        <Text style={styles.col1}>
                          {data?.document || '---'}
                        </Text>

                        {data?.amount > 0 ? (
                          <Text style={styles.in}>
                            {CurrencyFormatter.formatMoney(data?.amount) ||
                              '---'}
                          </Text>
                        ) : (
                          <Text style={styles.out}>
                            {CurrencyFormatter.formatFee(data?.amount) || '---'}
                          </Text>
                        )}
                        {data?.fee_amount > 0 ? (
                          <Text style={styles.in}>
                            {CurrencyFormatter.formatFee(data?.fee_amount) ||
                              '---'}
                          </Text>
                        ) : (
                          <Text style={styles.out}>
                            {CurrencyFormatter.formatFee(data?.fee_amount) ||
                              '---'}
                          </Text>
                        )}
                        {/* {data?.after - data?.before > 0 ? (
                          <Text style={styles.in}>
                            {formatMoney(data?.after - data?.before) || '---'}
                          </Text>
                        ) : (
                          <Text style={styles.out}>
                            {formatMoney(data?.after - data?.before) || '---'}
                          </Text>
                        )} */}
                      </View>
                    ))
                  : ''}
                <View style={styles.line}>
                  <Text style={styles.inTotal}>TOTAL</Text>
                  <Text style={styles.total_}> </Text>
                  <Text style={styles.total}>
                    {item.transactions.length > 0
                      ? CurrencyFormatter.formatFee(
                          item.transactions.reduce(
                            (acc, t) => acc + t.amount,
                            0,
                          ),
                        )
                      : CurrencyFormatter.formatMoney(0)}
                  </Text>
                  <Text style={styles.total}>
                    {item.transactions.length > 0
                      ? CurrencyFormatter.formatFee(
                          item.transactions.reduce(
                            (acc, t) => acc + t.fee_amount,
                            0,
                          ),
                        )
                      : CurrencyFormatter.formatMoney(0)}
                  </Text>
                </View>
              </View>
            </View>
          ))
        : ''}
    </View>
  );
}
