import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-people-svg-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-people-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(236, 253, 243);
    }
  }

  .svg-elem-1 {
    animation: animate-people-svg-stroke-1 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-people-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-people-svg-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-people-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4129_14700');
    }
  }

  .svg-elem-2 {
    animation: animate-people-svg-stroke-2 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-people-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-people-svg-stroke-3 {
    0% {
      stroke-dashoffset: 128.2389373779297px;
      stroke-dasharray: 128.2389373779297px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.2389373779297px;
    }
  }

  .svg-elem-3 {
    animation: animate-people-svg-stroke-3 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-people-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-people-svg-stroke-4 {
    0% {
      stroke-dashoffset: 57.78428268432617px;
      stroke-dasharray: 57.78428268432617px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57.78428268432617px;
    }
  }

  @keyframes animate-people-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-4 {
    animation: animate-people-svg-stroke-4 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-people-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }

  @keyframes animate-people-svg-stroke-5 {
    0% {
      stroke-dashoffset: 28.880611419677734px;
      stroke-dasharray: 28.880611419677734px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 28.880611419677734px;
    }
  }

  @keyframes animate-people-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-5 {
    animation: animate-people-svg-stroke-5 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-people-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }

  @keyframes animate-people-svg-stroke-6 {
    0% {
      stroke-dashoffset: 72.88581848144531px;
      stroke-dasharray: 72.88581848144531px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 72.88581848144531px;
    }
  }

  @keyframes animate-people-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-6 {
    animation: animate-people-svg-stroke-6 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-people-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
        both;
  }

  @keyframes animate-people-svg-stroke-7 {
    0% {
      stroke-dashoffset: 25.414119720458984px;
      stroke-dasharray: 25.414119720458984px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 25.414119720458984px;
    }
  }

  @keyframes animate-people-svg-fill-7 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(15, 64, 152);
    }
  }

  .svg-elem-7 {
    animation: animate-people-svg-stroke-7 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
      animate-people-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.4000000000000001s both;
  }
`;

const IconAwaitInvoice = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height="78"
      fill="none"
      viewBox="0 0 134 78"
    >
      <path
        fill="url(#paint0_linear_490_1615)"
        d="M132 11.98c-21.121 1.76-22.429 43.897-43.333 48.75-17.239 4.001-25.968-18.714-43.334-16.25C26.497 47.152 20.47 70.823 2 76.98h130z"
        className="svg-elem-3"
      ></path>
      <path
        stroke="#C92519"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.031"
        d="M2 76.98c18.47-6.157 24.497-29.828 43.333-32.5C62.7 42.016 71.428 64.73 88.667 60.73 109.57 55.877 110.879 13.74 132 11.98"
        className="svg-elem-2"
      ></path>
      <path
        stroke="#C92519"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.858"
        d="M98.262 27.236v-5.002a6.43 6.43 0 0 0-6.431-6.43h-2.858a2.144 2.144 0 0 1-2.144-2.145v-2.858a6.43 6.43 0 0 0-6.43-6.43h-3.573m4.287 0h-9.289c-1.183 0-2.143.96-2.143 2.143v32.868c0 1.184.96 2.144 2.143 2.144h24.294c1.183 0 2.144-.96 2.144-2.144V21.52A17.15 17.15 0 0 0 81.113 4.371"
        className="svg-elem-3"
      ></path>
      <path
        fill="#C92519"
        fillRule="evenodd"
        d="M74.444 32.476c0-1.052.853-1.906 1.906-1.906h15.243c1.052 0 1.905.854 1.905 1.906v3.81a1.905 1.905 0 0 1-1.905 1.906H76.35a1.905 1.905 0 0 1-1.906-1.905zm13.938 3.915c-.798 0-1.358-.582-1.358-1.413 0-.842.549-1.424 1.337-1.424.804 0 1.315.538 1.315 1.375v.201l-2.022.005c.05.473.3.712.74.712.363 0 .603-.14.679-.396h.614c-.114.587-.603.94-1.305.94m-.016-2.293c-.391 0-.63.212-.695.614h1.347c0-.37-.255-.614-.652-.614m-3.445.478v.587h1.75v-.587zm-.538.12h-1.478v1.624h-.695v-3.972h2.456v.641h-1.76v1.081h1.477zm-6.128 1.624h.684v-2.815l1.87 2.815h.684v-3.972h-.684v2.815l-1.864-2.815h-.69z"
        clipRule="evenodd"
        className="svg-elem-3"
      ></path>
      <path
        fill="#C92519"
        d="M83.865 28.302a5.284 5.284 0 0 1-5.28-5.28c0-1.622.723-3.122 1.98-4.122a5.33 5.33 0 0 1 3.3-1.156 5.284 5.284 0 0 1 5.279 5.279 5.287 5.287 0 0 1-5.28 5.279m0-8.891c-.822 0-1.6.278-2.256.8a3.58 3.58 0 0 0-1.356 2.812 3.62 3.62 0 0 0 3.612 3.612c.867 0 1.711-.323 2.39-.9a3.6 3.6 0 0 0 1.222-2.712 3.62 3.62 0 0 0-3.612-3.612"
        className="svg-elem-3"
      ></path>
      <path
        fill="#C92519"
        d="M85.754 24.382a.178.178 0 0 1-.059.292.18.18 0 0 1-.195-.04l-1.483-1.483-1.484 1.484a.179.179 0 1 1-.253-.253l1.484-1.484-1.484-1.483a.179.179 0 1 1 .253-.254l1.484 1.484 1.483-1.484a.179.179 0 0 1 .254.253l-1.484 1.484z"
        className="svg-elem-3"
      ></path>
      <path
        fill="#C92519"
        fillRule="evenodd"
        d="M86.047 24.088a.593.593 0 1 1-.84.84l-1.19-1.19-1.19 1.19a.594.594 0 0 1-.84-.84l1.19-1.19-1.19-1.19a.594.594 0 1 1 .84-.84l1.19 1.19 1.19-1.19a.594.594 0 0 1 .84.84l-1.19 1.19zm-.294-2.674a.179.179 0 1 0-.253-.253l-1.443 1.444-.04.04-.04-.04-1.444-1.444a.18.18 0 0 0-.305.127c0 .047.018.093.052.126l1.443 1.444.04.04-.04.04-1.443 1.444a.179.179 0 0 0 .253.253l1.443-1.444.04-.04.041.04 1.443 1.444a.18.18 0 0 0 .127.052.18.18 0 0 0 .127-.305l-1.444-1.443-.04-.04.04-.041z"
        clipRule="evenodd"
        className="svg-elem-3"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_490_1615"
          x1="67"
          x2="67"
          y1="54.114"
          y2="76.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE4E4"></stop>
          <stop offset="1" stopColor="#FFE4E4" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconAwaitInvoice };
