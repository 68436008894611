import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

async function exportInvoices(invoices, setProgress, progress, setLoading) {
  const zip = new JSZip();
  let hasFiles = false;
  let successCount = 0;
  let errorCount = 0;

  setLoading({ export: true });
  setProgress({ current: 0, total: invoices.length });

  for (const invoice of invoices) {
    try {
      const response = await fetch(invoice.url);
      if (!response.ok) {
        console.error(
          `Failed to fetch image for invoice ${invoice.key}: ${response.statusText}`,
        );
        continue;
      }
      const blob = await response.blob();
      //eslint-disabled-next-line
      const name =
        `NFSe_${invoice?.invoice_number}_${invoice?.source_company?.cnpj}_${invoice?.source_company?.accountable}_${invoice?.source_company?.date_added}_${invoice.key}.jpg`.replace(
          /[/\s]/g,
          '',
        );

      zip.file(name, blob);
      hasFiles = true;
      successCount++;
      setProgress((prevProgress) => ({
        ...prevProgress,
        current: prevProgress.current + 1,
      }));
    } catch (error) {
      errorCount++;

      console.error(`Error fetching image for invoice ${invoice.key}:`, error);
    }
  }

  if (!hasFiles) {
    throw new Error('No images were downloaded, zip file will not be created.');
  }

  try {
    const today = format(new Date(), 'dd-MM-yyyy_HH-mm-ss');
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `Selecao_NFS-${today}.zip`);
  } catch (error) {
    console.error('Error generating zip file:', error);
    throw new Error('Failed to generate zip file.');
  }

  return { successCount, errorCount };
}

export default exportInvoices;
