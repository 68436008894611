import { saveAs } from 'file-saver';
import React from 'react';
import { ExcelGenerator } from './ExcelGenerator';
import { ExportButton } from './ExportButton';
import { formatExcelData } from './formatExcelData';

export const ExportExcelExtractSuccess = ({ list, title }) => {
  const handleExportClick = () => {
    const success = list.filter((v) => v.type === 2 && v.status === '06');
    const formattedData = formatExcelData(success);
    const excelBuffer = ExcelGenerator.generateExcel(formattedData);

    const blobData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    saveAs(blobData, 'Relatório_extrato.xlsx');
  };

  return <ExportButton title={title} onClick={handleExportClick} />;
};
