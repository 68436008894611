import React, { memo, useState } from 'react';
import { useHistory } from 'react-router';
import { Loader } from 'rsuite';
import {
  IconEyes,
  IconPlusNotCircle,
} from '../../../../components/Details/Icons';
import Status from '../../../../components/UI/Status';
import { useTags } from '../../../../hooks/useTags';
import { useUser } from '../../../../hooks/useUser';
import { formatStatusOnboarding } from '../../../../utils/formatCollaborator';
import { formatDocument } from '../../../../utils/formatDocument';
import { IconClose } from '../TagInput/icon';
import TagInput from './Components/TagInput';
import styles from './styles.module.scss';

/**
 * Um componente React que representa um cartão de informações de um colaborador.
 *
 * @param {object} props - As propriedades do componente.
 * @param {string} props.name - O nome do colaborador.
 * @param {string} props.date_added - A data de adição do colaborador.
 * @param {string} props.phone_number - O número de telefone do colaborador.
 * @param {Object} props.status - O status do colaborador.
 * @param {string} props.status.id - O ID do status do colaborador.
 * @param {string} props.status.text - O nome do status do colaborador.
 * @param {string} props.status.description - A descrição do status do colaborador.
 * @param {string} props.status.color - A cor do status do colaborador.
 * @param {string} props.document - O documento do colaborador.
 * @param {string} props.id - O ID do colaborador UUID.
 * @returns {JSX.Element} O cartão de informações do colaborador.
 */
const CardCollaborator = ({
  name,
  date_added,
  phone_number,
  status,
  is_active,
  reproval_reasons,
  document,
  description,
  type_document,
  id,
  tags,
  toggleSelect,
  listSelect,
  handleReload,
  selectCheckedTags,
}) => {
  const history = useHistory();

  const [tagsEdit, setTagsEdit] = useState([]);
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const permissionTags = [40].every((item) => user?.permission.includes(item));
  const [openEditTags, setOpenEditTags] = useState(false);
  const { removeTag, updateTags } = useTags();
  const handleUpdateTags = async () => {
    setLoading({ key: id });

    try {
      const result = await updateTags(tags, tagsEdit, id);
      if (result) {
        await handleReload();
        setOpenEditTags(false);
        setTagsEdit([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateTagsSelect = async (tag) => {
    setLoading({ id });
    try {
      const result = await removeTag([tag], [id]);
      if (result) {
        await handleReload();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  function handlePushRouter(id, status, is_active) {
    history.push({
      pathname: `/menu/collaborator/${id}`,
      state: { status, is_active },
    });
  }

  return (
    <>
      <div className={styles.container}>
        <span className={styles.input}>
          <input
            type="checkbox"
            checked={listSelect && listSelect.includes(id)}
            onChange={() => toggleSelect(id)}
            disabled={!permissionTags}
          />
        </span>
        <div
          className={styles.name}
          onClick={() => handlePushRouter(id, status)}
        >
          <span>{name}</span>
        </div>

        <div className={styles.type}>
          <span>{type_document}</span>
        </div>
        <div className={styles.document}>
          <span>{formatDocument(document)}</span>
        </div>
        <div className={styles.phone}>
          <span>{phone_number}</span>
        </div>
        <div className={styles.tags}>
          {loading?.id === id ? (
            <Loader />
          ) : (
            <span>
              {tags?.length > 0 ? (
                <>
                  <strong>
                    {tags.find((tag) => selectCheckedTags?.includes(tag))
                      ? tags.filter((tag) =>
                          selectCheckedTags?.includes(tag),
                        )[0]
                      : tags[0]}
                    {permissionTags && (
                      <button onClick={() => handleUpdateTagsSelect(tags[0])}>
                        <IconClose />
                      </button>
                    )}
                  </strong>
                  {tags?.length > 1 && <p>{`+${tags?.length - 1}`}</p>}
                </>
              ) : (
                ''
              )}
              <div className={styles.card}>
                {tags.find((tag) => selectCheckedTags?.includes(tag)) ? (
                  <>
                    {tags
                      ?.filter((item) => !selectCheckedTags.includes(item))
                      .map((item, index) => (
                        <strong key={index}>
                          {item}
                          <button onClick={() => handleUpdateTagsSelect(item)}>
                            <IconClose />
                          </button>
                        </strong>
                      ))}
                  </>
                ) : (
                  <>
                    {tags?.slice(1).map((item, index) => (
                      <strong key={index}>
                        {item}
                        <button onClick={() => handleUpdateTagsSelect(item)}>
                          <IconClose />
                        </button>
                      </strong>
                    ))}
                  </>
                )}
              </div>
            </span>
          )}
          {permissionTags && (
            <>
              {loading?.key === id ? (
                <Loader />
              ) : (
                <strong
                  className={styles.plus}
                  onClick={() => {
                    setOpenEditTags({ status: true, id });
                  }}
                >
                  <IconPlusNotCircle
                    size={'1.2rem'}
                    color={'var(--gray-850)'}
                  />
                </strong>
              )}
            </>
          )}
        </div>
        <div className={styles.date}>
          <span>{description}</span>
        </div>
        <div className={styles.date}>
          <span>{date_added}</span>
        </div>
        <div className={styles.status}>
          <Status
            status={formatStatusOnboarding(is_active === true ? 33 : 9)}
          />
        </div>
        <div className={styles.status}>
          <Status status={status} reproval_reasons={reproval_reasons} />
          {status?.text === 'Rejeitado' && (
            <div reason={reproval_reasons}>
              <p>{reproval_reasons || ''}</p>
            </div>
          )}
        </div>
        <div
          className={styles.eyes}
          onClick={() => handlePushRouter(id, status)}
        >
          <IconEyes />
        </div>
        {openEditTags.id === id && (
          <TagInput
            tags={tagsEdit.length > 0 ? tagsEdit : tags}
            setTags={setTagsEdit}
            handleUpdateTags={handleUpdateTags}
            isLoading={loading}
            setOpenEditTags={setOpenEditTags}
            active={openEditTags.id === id}
          />
        )}
      </div>
    </>
  );
};

export default memo(CardCollaborator);
