import { validateCNPJ, validateCPF } from 'validations-br';
import * as yup from 'yup';

export const ValidateAddCompany = yup.object().shape({
  social_reason: yup
    .string()
    .typeError('O campo deve receber um nome.')
    .required('Nome é Obrigatório'),
  accountable: yup
    .string()
    .typeError('O campo deve receber um Responsável.')
    .required('Responsável é Obrigatório'),
  fantasy_name: yup
    .string()
    .typeError('O campo deve receber o Nome Fantasia.')
    .required('Sobrenome é obrigatório'),
  street: yup
    .string()
    .typeError('O campo deve receber uma Rua.')
    .required('Rua é obrigatório'),
  contact: yup
    .string()
    .typeError('O campo deve receber um nome de contato.')
    .required('Contato é obrigatório'),
  neighborhood: yup
    .string()
    .typeError('O campo deve receber um Bairro.')
    .required('Bairro é obrigatório'),
  street_number: yup
    .string()
    .typeError('O campo deve receber um número.')
    .required('Número é obrigatório'),
  city: yup
    .string()
    .typeError('O campo deve receber uma Cidade.')
    .required('Cidade é obrigatória'),
  zipcode: yup
    .string()
    .typeError('O campo deve receber um CEP.')
    .required('CEP é obrigatório'),
  accountable_cpf: yup
    .string()
    .typeError('O campo deve receber um cpf.')
    .test('is-cpf', 'CPF não é valido', (value) => validateCPF(value))
    .required('CPF é obrigatório'),
  cnpj: yup
    .string()
    .typeError('O campo deve receber um CNPJ.')
    .test('is-cnpj', 'CNPJ não é valido', (value) => validateCNPJ(value))
    .required('CNPJ é obrigatório'),
  phone: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .max(15, 'O numero de telefone inválido'),
  tel_contato: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .max(15, 'O numero de telefone inválido'),
  email: yup
    .string()
    .typeError('O campo deve receber um email.')
    .required('Email é obrigatório')
    .email('E-mail deve ser valido'),
  company_type: yup
    .string()
    .typeError('O campo deve receber um tipo de empresa.')
    .required('Tipo de empresa é obrigatório'),
  linked_account_fee: yup.string().optional(),
  consignation_fee_rate: yup.string().optional(),
  transfer_fee: yup.string().optional(),
  internal_transfer_fee: yup.string().optional(),
});
