import * as React from 'react';

export function InvoiceAlert({ width = 42, height = 36 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 56 50"
    >
      <path
        stroke="#051532"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M28 20v10M3.192 39.002C.882 43.003 3.771 48 8.387 48h39.226c4.614 0 7.502-4.998 5.195-8.998L33.198 5.008c-2.31-4-8.086-4-10.395 0zM28 38h.019v.021H28z"
      ></path>
    </svg>
  );
}

export function InvoiceWrite({ width = 30, height = 30 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 36"
    >
      <rect width="36" height="36" fill="#fff" rx="18"></rect>
      <path
        stroke="#051532"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.098"
        d="m21.557 12.503 1.235-1.235a1.372 1.372 0 0 1 1.94 1.94L14.218 23.722c-.386.387-.864.67-1.388.827l-1.964.585.585-1.964c.156-.525.44-1.002.827-1.389l9.28-9.278zm0 0 1.93 1.93"
      ></path>
    </svg>
  );
}

export function InvoiceInfo({ width = 16, height = 16 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#900F0F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.111"
        d="m7.444 7.444.03-.015a.556.556 0 0 1 .788.631l-.524 2.101a.555.555 0 0 0 .787.632l.03-.015M14.667 8A6.667 6.667 0 1 1 1.332 8a6.667 6.667 0 0 1 13.335 0M8 5.222h.006v.006H8z"
      ></path>
    </svg>
  );
}
