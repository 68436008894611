import { DashboardData } from '../interfaces/DashboardData';
import { DashboardSummary } from '../interfaces/DashboardSummary';

export function formatDashboard(data: DashboardData[]): DashboardSummary {
  if (data.length === 0) {
    throw new Error('Dados do dashboard estão vazios');
  }

  const initialBalance = data[data.length - 1].before;

  let totalInputs = 0;
  let totalOutputs = 0;

  for (const item of data) {
    for (const transaction of item.transactions) {
      if (transaction.title.OFX === 'CREDIT') {
        totalInputs += Math.abs(transaction.amount);
      } else if (transaction.title.OFX === 'DEBIT') {
        totalOutputs += Math.abs(transaction.amount);
      }
    }
  }
  const finalBalance = parseFloat(data[0].after.toFixed(2));

  return {
    initialBalance,
    totalInputs,
    totalOutputs,
    finalBalance,
  };
}
