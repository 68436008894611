import React, { memo, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';

const FormTextarea = ({
  name,
  label,
  disabled,
  register,
  errors,
  onBlur,
  setValue,
  autofocus,
  clean,
  data,
  placeholder,
}) => {
  const [formValue, setFormValue] = useState(data?.value);

  useEffect(() => {
    if (clean) {
      setFormValue(' ');
    }
  }, [clean]);

  useEffect(() => {
    if (formValue) {
      setValue(name, formValue);
    }
    // eslint-disable-next-line
  }, [formValue]);
  const inputFocus = useCallback((inputElement) => {
    if (inputElement && autofocus) {
      inputElement.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <label className="font-weight-bold">{label}</label>
      <textarea
        disabled={disabled}
        {...register(name)}
        onBlur={onBlur}
        autofocus={autofocus}
        placeholder={placeholder}
        onChange={(e) => setFormValue(e.target.value)}
        value={formValue}
        ref={inputFocus}
      />
      {errors[name] && (
        <span className={styles.error__input}>{errors[name].message}</span>
      )}
    </div>
  );
};

export default memo(FormTextarea);
