import { useContext } from 'react';
import { CustomersContext } from '../data/customersContext';
import { StoreContext } from '../data/storeContext';

import {
  apiGet,
  apiOptions,
  apiPost,
  apiPut,
  apiPutEditUser,
} from '../services/api';
import { formatCustomers } from '../utils/formatCustomers';
import { useNotification } from './useNotifications';
import { useUser } from './useUser';
import { useQueryClient } from 'react-query';
import { getCustomerByDocument } from './services/Customers';
import { editCustomer } from '../usecases/customers/editCustomers';
import { sendLog } from '../usecases/logs/logs';
import { LogActions, LogTypes } from '../domain/emuns/LogActions';
import { data } from '../pages/Extract/data';
import { useHistory } from 'react-router-dom';

export function useCustomers() {
  const {
    customers,
    setCustomers,
    allCapabilities,
    setAllCapabilities,
    allProfiles,
    setAllProfiles,

    loading,
    setLoading,

    isNewCustomer,
    setIsNewCustomer,
    //edit form
    edit,
    setEdit,
    //collaborator
    usersCollaborator,
    setUsersCollaborator,

    isNewCustomerUpload,
    setIsNewCustomerUpload,
  } = useContext(CustomersContext);
  const { userLogout } = useUser();
  const { setError, error, setMessage, message } = useContext(StoreContext);
  const { addNotification } = useNotification();
  const { location } = useHistory();
  const queryClient = useQueryClient();

  async function fetchCustomers() {
    try {
      setLoading({ customers: true, add: false });

      setCustomers([]);

      const { data } = await apiGet('/customer/list');
      const { data: profiles } = await apiOptions('/Customer/Profiles');

      const isCustomers = data
        .map((item) => formatCustomers(item))
        .map((customer) => ({
          ...customer,
          name_profile: customer.profile
            ? profiles.find((profile) => profile.id === customer.profile)?.name
            : 'Indefinido',
        }))
        .sort((a, b) => {
          const dateA = a.date_added
            ? new Date(a.date_added.split('/').reverse().join('-'))
            : new Date(0);
          const dateB = b.date_added
            ? new Date(b.date_added.split('/').reverse().join('-'))
            : new Date(0);
          return dateB - dateA;
        });

      setCustomers(isCustomers);

      return isCustomers;
    } catch (error) {
      const err = await error.response;
      if (err?.status === 401) {
        setError({ login: 'Você não está autenticado' });
        userLogout();
      }
      if (err?.status === 403) {
        setError({ login: 'Usuário sem autorização' });
        setCustomers([]);
      }
      if (err?.status === 500) {
        setError({ login: 'Error servidor' });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }
  //criação de customer
  async function fetchAddCustomers(body) {
    try {
      setLoading({ customers: false, add: true });

      const document = body.document.replace(/[^\d]/g, '');
      const existingCustomer = await getCustomerByDocument(document);

      return await handleNewCustomer(existingCustomer, body);
    } catch (error) {
      handleFetchAddCustomersError(error);
    } finally {
      setLoading({ customers: false, add: false });
      await queryClient.invalidateQueries(['customerListData']);
    }
  }

  async function handleNewCustomer(existingCustomer, body) {
    if (existingCustomer) {
      return await updateCustomerData(existingCustomer);
    }

    return await createNewCustomer(body);
  }

  async function updateCustomerData(existingCustomer) {
    if (existingCustomer?.is_active === false) {
      addNotification(
        <>Esse usuário está inativo, não é possível alterar seus dados.</>,
        2,
        'Perfil',
        true,
      );
      return;
    }

    try {
      const customerData = {
        is_active: true,
        first_name: existingCustomer.first_name,
        last_name: existingCustomer.last_name,
        document: existingCustomer.document,
        email: existingCustomer.email,
        telephone: existingCustomer.telephone,
      };

      const { data } = await apiPut(`/customer`, customerData);
      if (data) {
        addSuccessNotification(data, false);
        setIsNewCustomer(false);
        await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.SUCCESS, {
          customer_id: data.customer_id,
          payload: data,
          path: location.pathname,
        });
      } else {
        await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.ERROR, {
          customer_id: existingCustomer.customer_id,
          payload: data,
          path: location.pathname,
          error: 'Sem retorno de dados do usuário',
        });
      }

      return data;
    } catch (error) {
      await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.ERROR, {
        customer_id: existingCustomer.customer_id,
        error: error.message,
        path: location.pathname,
      });
    }
  }

  async function createNewCustomer(body) {
    try {
      const customerData = {
        is_active: true,
        first_name: body.first_name,
        last_name: body.last_name,
        document: body.document,
        email: body.email,
        telephone: body.telephone,
      };

      const { data } = await apiPutEditUser(
        `/customer`,
        customerData,
        body.company,
      );

      if (data) {
        addSuccessNotification(data, body.edit);
        setIsNewCustomer(false);
        await sendLog(LogActions.CREATE_CUSTOMER, LogTypes.SUCCESS, {
          customer_id: data.customer_id,
          payload: data,
          path: location.pathname,
        });
      } else {
        await sendLog(LogActions.CREATE_CUSTOMER, LogTypes.ERROR, {
          payload: body,
          error: 'Sem retorno de dados do usuário',
          path: location.pathname,
        });
      }

      return data;
    } catch (error) {
      await sendLog(LogActions.CREATE_CUSTOMER, LogTypes.ERROR, {
        customer_id: data.customer_id,
        payload: data,
        error: error.message,
      });
    }
  }

  function addSuccessNotification(data, isEdit) {
    addNotification(
      <>
        {data?.first_name}, {isEdit ? 'Editado' : 'Cadastrado'} com sucesso
      </>,
      0,
      'Perfil',
      true,
    );
  }

  function handleFetchAddCustomersError(error) {
    const status = error?.response?.status;
    if (status === 401) {
      userLogout();
      setError({ order: 'Você não está autenticado' });
    } else if (status === 500) {
      setError({ order: 'Erro no servidor' });
    }
  }

  //fim

  const fetchEditCustomers = async (customer) => {
    try {
      setLoading({ customers: false, add: true, id: customer.document });

      const user = await editCustomer(customer, setIsNewCustomer);
      if (user) {
        addNotification(
          <>{user?.first_name}, Editado com sucesso</>,
          0,
          'Perfil',
          true,
        );
        setIsNewCustomer(false);

        await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.SUCCESS, {
          customer_id: customer.customer_id,
          payload: customer,
          path: location.pathname,
        });
        await queryClient.invalidateQueries(['customerListData']);
      }
    } catch (error) {
      await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.ERROR, {
        customer_id: customer.customer_id,
        error: error.message,
        path: location.pathname,
      });

      setError({ login: 'Não foi possível editar' });
    } finally {
      setLoading(false);
    }
  };

  async function fetchPasswordReset(body) {
    try {
      setLoading({ login: true });

      const { data } = await apiPost(`/Customer/Password`, {
        current_password: body.current_password,
        new_password: body.new_password,
      });

      if (data) {
        addNotification(
          <>{data?.first_name}, Editado com sucesso</>,
          0,
          'Perfil',
          true,
        );
        await sendLog(LogActions.RESET_PASSWORD_CUSTOMER, LogTypes.SUCCESS, {
          payload: data,
          error: error.message,
          path: location.pathname,
        });
      }
    } catch (error) {
      setError({
        login: `Não foi possível Alterar senha de ${body?.first_name}`,
      });
    } finally {
      setLoading({ login: true });
    }
  }

  async function fetchCapabilities() {
    try {
      setLoading({ customers: true });

      const { data } = await apiOptions('/Customer/Capabilities');
      setAllCapabilities(data);
    } catch (error) {
      setError({ order: 'Não foi possível obter as opções de Permissões.' });
    } finally {
      setLoading({ customers: true });
    }
  }

  async function fetchProfiles() {
    try {
      setLoading({ customers: true });

      const { data } = await apiOptions('/Customer/Profiles');

      setAllProfiles(data);
    } catch (error) {
      setError({ order: 'Não foi possível obter as opções de Perfis.' });
    } finally {
      setLoading({ customers: true });
    }
  }

  async function fetchEditProfile(customer_id, profile) {
    try {
      if (profile === null || profile === undefined) {
        addNotification(
          <>Não foi possível alterar este perfil</>,
          2,
          'Error',
          true,
        );
        return;
      }
      if (!customer_id) {
        addNotification(
          <>Não foi possível alterar este usuário.</>,
          2,
          'Error',
          true,
        );
        return;
      }
      setLoading({ customers: false, add: true });

      const { data } = await apiPost('/Customer/Profile', {
        customer_id,
        profile,
      });
      if (data) {
        if (profile === 0) {
          await sendLog(LogActions.REMOVE_CUSTOMER, LogTypes.SUCCESS, {
            customer_id_update: customer_id,
            profile_update: profile,
            path: location.pathname,
          });
        } else {
          await sendLog(LogActions.UPDATE_PROFILE, LogTypes.SUCCESS, {
            customer_id_update: customer_id,
            profile_update: profile,
            path: location.pathname,
          });
        }
      }
      await queryClient.invalidateQueries(['customerListData']);
      !data?.error &&
        data?.value &&
        addNotification(<>Perfil alterado com sucesso!</>, 3, 'Perfil', true);

      if (data?.error) {
        await sendLog(LogActions.UPDATE_PROFILE, LogTypes.ERROR, {
          customer_id: data.customer_id,
          error: data?.error?.message,
          path: location.pathname,
        });
        addNotification(
          <>
            {data?.error?.message === 'Unauthorized'
              ? 'Não autorizado.'
              : 'Não foi possível alterar o perfil, tente novamente.'}
          </>,
          2,
          'Error',
          true,
        );
      }
    } catch (error) {
      if (error?.response) {
        const { status } = error.response;

        await sendLog(LogActions.UPDATE_CUSTOMER, LogTypes.ERROR, {
          customer_id: data.customer_id,
          error: error.message,
          path: location.pathname,
        });
        if (status === 401) {
          userLogout();
          addNotification(<>Você não está autenticado</>, 2, 'Error', true);
        } else {
          addNotification(
            <>Não foi possível alterar o perfil, tente novamente. </>,
            2,
            'Error',
            true,
          );
        }
      } else {
        addNotification(
          <>Erro inesperado ao tentar alterar o perfil.</>,
          2,
          'Error',
          true,
        );
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }

  async function fetchEditCapabilities(
    customer_id,
    capabilities,
    company_id,
    action,
  ) {
    try {
      setLoading({ customers: false, add: true });

      const { data } = await apiPost(`/Customer/Capabilities/${action}`, {
        customer_id,
        capabilities,
      });

      if (!data?.error && data?.value) {
        await sendLog(
          LogActions.UPDATE_CUSTOMER_CAPABILITIES,
          LogTypes.SUCCESS,
          {
            customer_id_update: customer_id,
            payload: {
              capabilities,
              company_id,
              action,
            },
            path: location.pathname,
          },
        );
        setMessage({ login: 'Permissões alteradas com sucesso!' });
        await queryClient.invalidateQueries(['customerListData']);
      } else {
        await sendLog(LogActions.UPDATE_CUSTOMER_CAPABILITIES, LogTypes.ERROR, {
          customer_id: data.customer_id,
          error: data?.error?.message,
          path: location.pathname,
        });
        setError({
          order:
            data?.error?.message === 'Unauthorized'
              ? 'Não autorizado.'
              : 'Não foi possível alterar os permissões, tente novamente.',
        });
      }
    } catch (error) {
      const { status } = await error.response;
      await sendLog(LogActions.UPDATE_CUSTOMER_CAPABILITIES, LogTypes.ERROR, {
        customer_id: data.customer_id,
        error: error.message,
        path: location.pathname,
      });
      if (status === 401) {
        userLogout();

        setError({ order: 'Você não está autenticado' });
      } else {
        setError({
          order: 'Não foi possível alterar as Permissões, tente novamente.',
        });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }

  return {
    loading,
    setLoading,

    error,
    setError,

    customers,
    setCustomers,

    message,
    setMessage,

    allCapabilities,
    allProfiles,

    fetchCustomers,
    fetchAddCustomers,
    fetchEditCustomers,
    fetchCapabilities,
    fetchProfiles,
    fetchEditProfile,
    fetchEditCapabilities,

    isNewCustomer,
    setIsNewCustomer,
    //edit form
    edit,
    setEdit,

    isNewCustomerUpload,
    setIsNewCustomerUpload,
    fetchPasswordReset,
    //collaborator
    usersCollaborator,
    setUsersCollaborator,
  };
}
