import { LogEntry } from '../domain/entities/LogEntry';
import { apiPut } from './api';

export const logService = {
  sendLog: async (logEntry: LogEntry) => {
    try {
      await apiPut('/log/web', [logEntry]);
    } catch (error) {
      throw new Error('Erro ao enviar log: ' + error.message);
    }
  },
};
