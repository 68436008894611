import React, { memo, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import styles from './styles.module.scss';

import { Details } from '../../../../components/Details';
import { IconArrow } from '../../../../components/Details/Icons';
import { useView } from '../../../../hooks/useView';
import { apiGetBalance } from '../../../../services/api';
import { formatDate } from '../../../../utils/formatDate';

function CardFeeDetails({ companyId, details, setDetails }) {
  const { view, ref } = useView({
    /* Optional options */
    rootMargin: '100px 0px 0px 0px',
    root: null,
    threshold: 0.5,
  });
  const [keys, setKeys] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getKeyValues = async () => {
      const { data } = await apiGetBalance(
        '/company/keyvalues/consignation_allocable_amount',
        companyId,
      );

      setKeys(data.key_value_histories.reverse());
      setUsers(data.customers);
    };
    getKeyValues();
  }, [companyId]);

  return (
    <>
      {!!details ? (
        <div className={styles.flexCard}>
          <div className={styles.header_flexCard}>
            <h2>Histórico</h2>
            <button className={styles.close} onClick={() => setDetails(false)}>
              fechar
              <MdClose size={'1.5rem'} />
            </button>
          </div>
          <div className={styles.details}>
            {!view && (
              <div className={styles.ico}>
                <IconArrow />
              </div>
            )}

            {keys.length ? (
              keys.map((v) => (
                <Details
                  value={
                    <div>
                      <strong> Tipo: </strong>{' '}
                      {v?.key === 'consignation_allocable_amount'
                        ? 'Alocação'
                        : ''}
                      <br />
                      <br />
                      <strong>Valor:</strong> {v?.value}
                      <br />
                      <br />
                      <strong>Data:</strong>{' '}
                      {formatDate(v?.updated_at, "dd MMM yyyy 'às' HH:mm")}
                      <br />
                      <br />
                      <strong>Nome:</strong>{' '}
                      {
                        users.find((u) => u.id === v.update_customer_id)
                          .first_name
                      }{' '}
                      {
                        users.find((u) => u.id === v.update_customer_id)
                          .last_name
                      }
                      <br />
                      <br />
                      <strong>Documento:</strong>{' '}
                      {
                        users.find((u) => u.id === v.update_customer_id)
                          .document
                      }
                      <br />
                      <br />
                      <strong>Email:</strong>{' '}
                      {users.find((u) => u.id === v.update_customer_id).email}
                      <br />
                      <br />
                      <strong>Telefone:</strong>{' '}
                      {
                        users.find((u) => u.id === v.update_customer_id)
                          .telephone
                      }
                    </div>
                  }
                  description={true}
                  border={false}
                  maxWidth="60rem"
                  padding="0.39rem 0.89rem"
                />
              ))
            ) : (
              <p>Nada encontrado</p>
            )}
            <div ref={ref} className={styles.bottom}></div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default memo(CardFeeDetails);
