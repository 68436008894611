import { addDays, endOfWeek, startOfWeek, subDays } from 'date-fns';
/**
 * Este arquivo contém predefinições de faixas de datas que podem ser usadas com o componente `DatePicker`.
 *
 * As predefinições de faixas de datas fornecem intervalos comuns de datas que os usuários podem selecionar facilmente no seletor de datas.
 *
 * @module data
 */

const predefinedRangesExtract = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Semana Passada',

    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 60 dias',
    value: [subDays(new Date(), 59), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 90 dias',
    value: [subDays(new Date(), 89), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 180 dias',
    value: [subDays(new Date(), 179), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Sem período',
    value: [subDays(new Date(), 365 * 10), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
];

const predefinedRangesAnticipation = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Semana Passada',

    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 31 dias',
    value: [subDays(new Date(), 30), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 60 dias',
    value: [subDays(new Date(), 59), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 90 dias',
    value: [subDays(new Date(), 89), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
];

const predefinedRangesPayment = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Semana Passada',

    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 31 dias',
    value: [subDays(new Date(), 30), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 60 dias',
    value: [subDays(new Date(), 59), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 90 dias',
    value: [subDays(new Date(), 89), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
];

const predefinedRangesInvoice = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Esta semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Semana Passada',

    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 60 dias',
    value: [subDays(new Date(), 59), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
  {
    label: 'Últimos 90 dias',
    value: [subDays(new Date(), 89), new Date()],
    placement: 'left',
    appearance: 'subtle',
  },
];

export {
  predefinedRangesAnticipation,
  predefinedRangesExtract,
  predefinedRangesInvoice,
  predefinedRangesPayment,
};
