import { User } from '../domain/entities/UserResponseEntry';

export const formatResponseUser = (user: Partial<User>): User => {
  return {
    is_active: user.is_active ?? false,
    first_name: user.first_name ?? '',
    last_name: user.last_name ?? '',
    document: user.document ?? '',
    telephone: user.telephone ?? '',
    email: user.email ?? '',
  };
};
