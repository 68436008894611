import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-svg-trans-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-svg-trans-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 250, 235);
    }
  }

  .svg-elem-1 {
    animation: animate-svg-trans-stroke-1 1.5s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-trans-fill-1 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-svg-trans-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-svg-trans-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4167_116');
    }
  }

  .svg-elem-2 {
    animation: animate-svg-trans-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.12s both,
      animate-svg-trans-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-svg-3-stroke-1 {
    0% {
      stroke-dashoffset: 128.2389373779297px;
      stroke-dasharray: 128.2389373779297px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.2389373779297px;
    }
  }

  .svg-elem-3 {
    animation: animate-svg-3-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.24s both,
      animate-svg-3-fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-svg-trans-stroke-4 {
    0% {
      stroke-dashoffset: 158.84959411621094px;
      stroke-dasharray: 158.84959411621094px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 158.84959411621094px;
    }
  }

  @keyframes animate-svg-trans-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(181, 71, 8);
    }
  }

  .svg-elem-4 {
    animation: animate-svg-trans-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715)
        0.36s both,
      animate-svg-trans-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }
`;

const IconRefusedInvoice = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height="77"
      fill="none"
      viewBox="0 0 131 77"
    >
      <path
        fill="url(#paint0_linear_490_1553)"
        d="M129 11.306c-20.796 1.733-22.084 43.222-42.667 48-16.973 3.94-25.568-18.426-42.666-16C25.12 45.937 19.186 69.244 1 75.306h128z"
        className="svg-elem-3"
      ></path>
      <path
        stroke="#0F4098"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 75.306c18.186-6.062 24.12-29.369 42.667-32 17.098-2.426 25.693 19.94 42.666 16 20.583-4.778 21.871-46.267 42.667-48"
        className="svg-elem-2"
      ></path>
      <g clipPath="url(#clip0_490_1553)">
        <path
          stroke="#0F4098"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.814"
          d="M95.781 26.818v-4.925a6.33 6.33 0 0 0-6.331-6.332h-2.815a2.11 2.11 0 0 1-2.11-2.11v-2.814a6.33 6.33 0 0 0-6.332-6.332h-3.517m4.22 0h-9.145c-1.165 0-2.11.945-2.11 2.11v32.363c0 1.165.945 2.11 2.11 2.11h23.92c1.165 0 2.11-.945 2.11-2.11V21.19A16.885 16.885 0 0 0 78.897 4.305"
          className="svg-elem-3"
        ></path>
        <path
          fill="#0F4098"
          fillRule="evenodd"
          d="M72.331 31.978c0-1.037.84-1.877 1.876-1.877h15.009c1.036 0 1.876.84 1.876 1.877v3.752c0 1.036-.84 1.876-1.876 1.876H74.207a1.876 1.876 0 0 1-1.876-1.876zm13.724 3.854c-.786 0-1.337-.572-1.337-1.39 0-.83.54-1.402 1.316-1.402.792 0 1.295.53 1.295 1.353v.198l-1.99.005c.048.466.294.701.727.701.359 0 .594-.139.669-.39h.605c-.113.578-.594.925-1.285.925m-.016-2.257c-.385 0-.62.208-.684.604h1.326c0-.364-.251-.604-.642-.604m-3.392.47v.578h1.722v-.578zm-.53.118h-1.455v1.6h-.685v-3.911h2.418v.63h-1.733v1.066h1.455zm-6.034 1.6h.674V32.99l1.84 2.772h.675v-3.911h-.674v2.771l-1.835-2.771h-.68z"
          clipRule="evenodd"
          className="svg-elem-3"
        ></path>
        <path
          fill="#0F4098"
          d="M81.606 27.868a5.2 5.2 0 0 1-5.198-5.198c0-1.598.712-3.075 1.948-4.06a5.24 5.24 0 0 1 3.25-1.138 5.203 5.203 0 0 1 5.198 5.198 5.206 5.206 0 0 1-5.198 5.197m0-8.755c-.81 0-1.576.274-2.221.788a3.53 3.53 0 0 0-1.335 2.769 3.563 3.563 0 0 0 3.556 3.556c.854 0 1.685-.317 2.353-.886a3.55 3.55 0 0 0 1.203-2.67 3.563 3.563 0 0 0-3.556-3.557"
          className="svg-elem-3"
        ></path>
        <path
          fill="#0F4098"
          d="M80.512 24.585a.83.83 0 0 1-.7-.394.82.82 0 0 1 .284-1.127l.974-.58v-1.182c0-.449.372-.82.82-.82.449 0 .821.371.821.82v1.641a.82.82 0 0 1-.394.7l-1.368.822a.83.83 0 0 1-.437.12"
          className="svg-elem-3"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_490_1553"
          x1="65"
          x2="65"
          y1="52.791"
          y2="75.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4EFFF"></stop>
          <stop offset="1" stopColor="#E4EFFF" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_490_1553">
          <path fill="#fff" d="M65.63.084h32.16V45.11H65.63z"></path>
        </clipPath>
      </defs>
    </StyledSvg>
  );
};

export { IconRefusedInvoice };
