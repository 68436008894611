import { apiGet, apiPut } from '../../../services/api';

export interface Customer {
  is_active: boolean;
  first_name: string;
  last_name: string;
  document: string;
  email: string;
  telephone: string;
  date_added?: string;
}

export const getCustomerByDocument = async (document: string) => {
  const cleanedDocument = document.replace(/[^\d]/g, '');
  const { data } = await apiGet(`/Customer/document/${cleanedDocument}`);
  return data;
};

export const updateCustomer = async (customer: Customer) => {
  const { data } = await apiPut(`/customer`, customer);
  return data;
};
