import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { ExportButton } from './ExportButton';
import ExtractReportTemplate from './ExtractReportTemplate';
import { formatGroup } from './utils/formatGroup';

import { formatDateNoSubTime } from '../../../../utils/formatDate';
import { formatDashboard } from './utils/formatDashboard';
import { useHistory } from 'react-router-dom';
import { sendLog } from '../../../../usecases/logs/logs';
import { LogActions, LogTypes } from '../../../../domain/emuns/LogActions';
import { useNotification } from '../../../../hooks/useNotifications';

export const ExportPDFExtract = ({
  list,
  title,
  user,
  period,
  setLoadingPDF,
}) => {
  const { location } = useHistory();
  const { addNotification } = useNotification();
  async function HandleDownloadPDF() {
    const data = formatGroup(list);
    const report = formatDashboard(data);
    const doc = (
      <ExtractReportTemplate
        list={data}
        user={user}
        period={period}
        report={report}
      />
    );

    try {
      const blob = await pdf(doc).toBlob();
      const name =
        user?.fantasy_name ||
        user?.company?.social_name ||
        user?.company?.fantasy_name ||
        'do';
      // throw new Error('Erro ao gerar o PDF');
      const name_file = `Extrato_${name}_${formatDateNoSubTime(
        period.dateInitial,
      )}_a_${formatDateNoSubTime(period.dateFinally)}.pdf`;
      saveAs(blob, name_file);

      return true;
    } catch (error) {
      addNotification(<>Erro ao exportar extrato PDF.</>, 2, 'Error', true);
      await sendLog(LogActions.EXPORT_EXTRACT_PDF, LogTypes.ERROR, {
        data: {
          dateFinally: period.dateFinally,
          dateInitial: period.dateInitial,
          description: 'Erro ao exportar extrato PDF',
          path: location.pathname,
        },
      });
      return false;
    } finally {
      setLoadingPDF(false);
    }
  }

  async function HandleLogPDF() {
    setLoadingPDF(true);
    try {
      const res = HandleDownloadPDF();

      if (res) {
        await sendLog(LogActions.EXPORT_EXTRACT_PDF, LogTypes.SUCCESS, {
          dateFinally: period.dateFinally,
          dateInitial: period.dateInitial,
          description: `Exportação de extrato PDF`,
          path: location.pathname,
        });
      }
    } catch (error) {
      await sendLog(LogActions.EXPORT_EXTRACT_PDF, LogTypes.ERROR, {
        data: {
          dateFinally: period.dateFinally,
          dateInitial: period.dateInitial,
          error: error,
          desciption: 'Erro ao exportar extrato PDF',
        },
      });
    } finally {
      setLoadingPDF(false);
    }
  }

  return <ExportButton title={title} onClick={HandleLogPDF} />;
};
