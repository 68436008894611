import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  min-height: calc(100vh - 19.5rem);
  height: auto;
  background: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;
export const TableHeadColumn = styled.th`
  padding-top: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  color: var(--gray-900);
  font-size: 0.7rem;
`;

export const Warning = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: calc(100vh - 49.5rem);
`;

export const Header = styled.tr`
  background: ${(props) => (props.bg ? props.bg : `var(--white)`)};
  display: grid;
  grid-template-columns: 10rem 4rem 8rem auto 10rem 7rem 7rem 8rem 8rem 2rem;
  gap: 0.5rem;
  margin: 0.8rem 0;
  border-radius: 1rem;
  padding: 0.5rem 1rem;

  @media only screen and (min-width: 768px) and (max-width: 1899px) {
    grid-template-columns: 10rem 5rem 10rem auto 10rem 10rem 6rem 6rem 6rem 2rem;
    gap: 0.5rem;
  }
`;

export const Box = styled.tbody`
  gap: 0;
`;
