import React, { memo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Status from '../../../../components/UI/Status';
import { useAnticipation } from '../../../../hooks/useAnticipation';
import { useUser } from '../../../../hooks/useUser';
import { formatMoney } from '../../../../utils/formatMoney';
import styles from './styles.module.scss';

import { Details } from '../../../../components/Details';

import {
  IconArrow,
  IconCalendar,
  IconCalendarCheck,
  IconCollaborator,
  IconDocumentPerson,
  IconEyes,
  IconFile,
  IconGetCurrency,
  IconId,
  IconKey,
  IconOutCurrency,
  IconStatus,
} from '../../../../components/Details/Icons';
import { useView } from '../../../../hooks/useView';
import { formatDateSP } from '../../../../utils/formatDate';
import { formatDocument } from '../../../../utils/formatDocument';

function CardConsignment({
  id,
  statusRef,
  name,
  amount,
  document,
  date,
  error_description,
  description,
  used_amount,
  index,
  valid_since,
  valid_until,
  isFetching,
  file_name,
  id_consignable,
  user_id,
  approval_customer_id,
  useds,
  consignable_limit,
  additional_data,
}) {
  const { fetchAnticipationCancelKeyMovement, loading } = useAnticipation();

  const [card, setCard] = useState({ index: false, status: false });
  const { view, ref } = useView({
    /* Optional options */
    rootMargin: '100px 0px 0px 0px',
    root: null,
    threshold: 0.5,
  });

  const { user, customerListData } = useUser();

  const find_author =
    customerListData.find((user) => user.customer_id === user_id) || false;
  const find_author_approve =
    customerListData.find(
      (user) => user.customer_id === approval_customer_id,
    ) || false;

  const author = find_author
    ? {
        name: `${find_author.first_name} ${find_author?.last_name}`,
        email: find_author.email,
        document: formatDocument(find_author?.document),
        customer_id: find_author.customer_id,
      }
    : false;
  const author_approve = find_author_approve
    ? {
        name: `${find_author_approve.first_name} ${find_author_approve?.last_name}`,
        email: find_author_approve.email,
        document: formatDocument(find_author_approve?.document),
        customer_id: find_author_approve.customer_id,
      }
    : false;
  const handleCancel = async () => {
    await fetchAnticipationCancelKeyMovement(id);
  };

  const statusChecked = [4, 3, 6, 2, 1, 10, 11].includes(statusRef?.id);

  return (
    <>
      <div key={id} className={styles.cardOrder}>
        <span className={styles.span_date}>{date ?? valid_since}</span>
        <label className={styles.name}>{name}</label>
        <span className={styles.document}>{document}</span>
        <span className={styles.span_date}>{valid_until ?? '--'}</span>
        <label className={styles.description}>{description}</label>

        <span className={styles.not_used_amount}>{`${formatMoney(
          consignable_limit?.amount,
        )}  `}</span>

        <span className={styles.percentage}>
          {used_amount > 0 ? (
            <span className={styles.used_amount}>
              {formatMoney(used_amount)}
            </span>
          ) : (
            <>{formatMoney(Number(0))}</>
          )}
        </span>
        <span className={styles.status}>
          <Status status={statusRef} />
          {statusRef.id === 7 && (
            <div className={styles.error}>
              <div className={styles['summary-item']}>
                <div className={styles.amount_}>
                  <div className={styles.circle_red}></div>
                  <span>{error_description}</span>
                </div>
              </div>
            </div>
          )}
        </span>
        <span
          className={styles.eyes}
          onClick={() => setCard({ index, status: true })}
        >
          <IconEyes />
        </span>
      </div>
      {card.index === index && card.status ? (
        <div className={styles.flexCard}>
          <div className={styles.header_flexCard}>
            <h2>Detalhes</h2>
            <button
              className={styles.close}
              onClick={() => setCard({ index: false, status: true })}
            >
              fechar
              <MdClose size={'1.5rem'} />
            </button>
          </div>
          <div className={styles.details}>
            {!view && (
              <div className={styles.ico}>
                <IconArrow />
              </div>
            )}
            {id && (
              <Details Icon={IconKey} title={'Chave:'} value={id} copy={true} />
            )}
            {name && (
              <Details
                Icon={IconCollaborator}
                title={'Colaborador'}
                value={name}
              />
            )}
            {document && (
              <Details
                Icon={IconDocumentPerson}
                title={'CPF / CNPJ:'}
                value={document}
                copy={true}
              />
            )}

            {date && (
              <Details Icon={IconCalendar} title={'Lançamento'} value={date} />
            )}
            {valid_until && (
              <Details
                Icon={IconCalendarCheck}
                title={'Pagamento'}
                value={valid_until}
              />
            )}

            {error_description && (
              <Details
                Icon={IconStatus}
                title={statusRef.id === 7 ? 'Error:' : 'Atenção:'}
                value={error_description}
              />
            )}

            {statusRef && (
              <Details
                Icon={IconStatus}
                title={'Status'}
                value={statusRef.text}
              />
            )}
            {file_name && (
              <Details Icon={IconFile} title={'Arquivo'} value={file_name} />
            )}

            {amount > 0 && (
              <Details
                Icon={IconOutCurrency}
                title={'Liberado'}
                value={formatMoney(amount)}
              />
            )}
            {used_amount > 0 && (
              <Details
                Icon={IconGetCurrency}
                title={'Utilizado'}
                value={
                  used_amount > 0 ? formatMoney(used_amount) : formatMoney(0)
                }
              />
            )}
            {additional_data && (
              <Details
                Icon={IconDocumentPerson}
                title={'Descrição da Operação:'}
                value={additional_data}
                description={true}
              />
            )}

            {id_consignable && (
              <Details
                Icon={IconId}
                title={'Id de Liberação:'}
                value={id_consignable}
                copy={true}
              />
            )}
            {useds.length ? (
              <div className={styles.detailsUsedBox}>
                <h4>Histórico de resgate:</h4>
                <div className={styles.detailsUsed}>
                  {useds.map((used) => (
                    <div className={styles.detailsUsedContent}>
                      <div>
                        <strong>Valor: </strong>
                        <p>{formatMoney(used.pending_amount)}</p>
                      </div>
                      <br />
                      <div>
                        <strong>Data: </strong>
                        <p>
                          {formatDateSP(used.billed_amount || used.created_at)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {author && (
              <Details
                title={'Criado:'}
                value={
                  <div>
                    <strong> Nome: </strong> {author?.name}
                    <br />
                    <br />
                    <strong> Documento:</strong> {author?.document}
                    <br />
                    <br />
                    <strong>E-mail:</strong> {author?.email}
                  </div>
                }
                description={true}
                border={false}
                maxWidth="60rem"
                padding="0.39rem 0.89rem"
              />
            )}
            {author_approve && (
              <Details
                title={'Aprovado:'}
                value={
                  <div>
                    <strong> Nome: </strong> {author_approve?.name}
                    <br />
                    <br />
                    <strong> Documento:</strong> {author_approve?.document}
                    <br />
                    <br />
                    <strong>E-mail:</strong> {author_approve?.email}
                  </div>
                }
                description={true}
                border={false}
                maxWidth="60rem"
                padding="0.39rem 0.89rem"
              />
            )}
            <div ref={ref} className={styles.bottom}></div>

            {[32].every((item) => user?.permission.includes(item)) && (
              <>
                {statusChecked && (
                  <button
                    onClick={handleCancel}
                    className={styles.button_cancel}
                    disabled={loading?.movement || isFetching}
                  >
                    {loading?.movement || isFetching
                      ? 'Carregando ...'
                      : 'Cancelar'}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default memo(CardConsignment);
