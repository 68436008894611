import styled from 'styled-components';

function verifyNumber(value) {
  const value_absolute_after = Math.abs(value?.after);
  const value_absolute_before = Math.abs(value?.before);
  if (value?.amount < 0) {
    return { color: 'var(--red-500)', operation: false };
  }
  if (value_absolute_after < value_absolute_before) {
    return {
      color: `var(--red-500)`,
      operation: true,
    };
  }

  if (value_absolute_before < value_absolute_after) {
    return {
      color: `var(--green)`,
      operation: false,
    };
  }
}
export const TableBodyItems = styled.td`
  cursor: ${(props) => (props.cursor ? props.cursor : 'normal')};
  opacity: ${(props) => (props.opacity ? props.opacity : '1')};
  padding: 1rem 0;
  text-align: center;
  vertical-align: center;
  font-weight: ${(props) => (props.bold ? props.bold : '400')};
  font-size: 0.8rem;
  color: ${(props) => (props.color ? props.color : `var(--gray-900)`)};
  margin: auto 0;
  width: 100%;
  overflow: hidden;
  svg {
    transform: translateY(0.375rem);
  }
`;
export const TableBodyItemsButton = styled.td`
  cursor: ${(props) => (props.cursor ? props.cursor : 'normal')};
  opacity: ${(props) => (props.opacity ? props.opacity : '1')};
  padding: 1rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.bold ? props.bold : '400')};
  font-size: 0.7rem;
  color: ${(props) => (props.color ? props.color : `var(--gray-850)`)};
  width: 100%;
  background: none;
  overflow: hidden;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const TableBodyMoney = styled.td`
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => verifyNumber(props.value).color};
  &::before {
    content: '${(props) => verifyNumber(props.value).operation && `- `}';
  }
`;

export const TableBodyLine = styled.tr`
  background: ${(props) => (props.bg ? props.bg : `var(--white)`)};

  display: grid;
  grid-template-columns: 10rem 4rem 8rem auto 10rem 7rem 7rem 8rem 8rem 2rem;
  gap: 0.5rem;
  margin: 0.8rem 0;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.15);

  @media only screen and (min-width: 768px) and (max-width: 1899px) {
    grid-template-columns: 10rem 5rem 10rem auto 10rem 10rem 6rem 6rem 6rem 2rem;
    gap: 0.5rem;
  }
`;
