import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { StoreContext } from '../../data/storeContext';
import { InvoiceAlert, InvoiceInfo, InvoiceWrite } from './Icons';
import styles from './styles.module.scss';

const ModalInvoiceRefuse = () => {
  const {
    invoiceRefuse,
    setInvoiceRefuse,
    invoiceMessage,
    setInvoiceMessage,
    loading,
  } = useContext(StoreContext);
  const [show, setShow] = useState(false);
  const [refuses, setRefuses] = useState([]);

  const filterRefuses = (value) => {
    if (!value) return [];
    const regex = new RegExp(value, 'i');
    return invoiceRefuse?.reasons.filter(
      (item) => typeof item === 'string' && regex.test(item),
    );
  };
  const schema = yup.object().shape({
    invoiceMessage: yup
      .string()
      .required('O motivo da recusa é obrigatório')
      .min(3, 'O motivo deve ter pelo menos 3 caracteres')
      .max(200, 'O motivo não pode ter mais que 200 caracteres'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const watchInvoiceMessage = watch('invoiceMessage');

  function handle(value) {
    setRefuses(filterRefuses(value));
    setShow(true);
  }

  useEffect(() => {
    setInvoiceMessage(watchInvoiceMessage || '');
  }, [watchInvoiceMessage, setInvoiceMessage]);

  const onSubmit = (data) => {
    invoiceRefuse.handle({ message: data.invoiceMessage });
    close();
  };

  function handleRefuseSelect(value) {
    setValue('invoiceMessage', value);
    setInvoiceMessage(value);
    setShow(false);
  }

  function close() {
    setInvoiceRefuse(false);
    setInvoiceMessage('');
    setShow(false);
    reset();
  }

  return (
    <div
      className={
        invoiceRefuse?.open ? styles.modalOverlay : styles.modalOverlay_
      }
    >
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={() => close()}>
          &times;
        </button>
        <InvoiceAlert />
        <h2 className={styles.title}>Deseja Recusar a Nota Fiscal?</h2>
        <p className={styles.description}>
          Informe brevemente o motivo para que o motorista seja notificado no
          aplicativo.
        </p>
        <hr />
        <p className={styles.subtitle}>Descreva o motivo de recusa:</p>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div
            className={styles.reasonInput}
            style={
              show && refuses.length > 0
                ? { borderRadius: '14px 14px 0 0', borderBottom: 'none' }
                : errors.invoiceMessage && !watchInvoiceMessage
                ? { border: '1px solid #f00' }
                : {}
            }
          >
            <InvoiceWrite />
            <input
              type="text"
              placeholder="Informe o motivo da recusa..."
              autoComplete="new-password"
              {...register('invoiceMessage', {
                onChange: (e) => {
                  handle(e.target.value);
                },
              })}
            />
          </div>
          {show && refuses.length > 0 && (
            <div className={styles.listRefuses}>
              <span>Motivos recentes:</span>
              <ul
                style={
                  !invoiceMessage.length && refuses.length > 4
                    ? { paddingTop: '8rem' }
                    : !invoiceMessage.length
                    ? { paddingTop: 'auto' }
                    : {}
                }
              >
                {refuses.map((refuse, index) => (
                  <li key={index} onClick={() => handleRefuseSelect(refuse)}>
                    <InvoiceInfo />
                    <p>{refuse}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {errors.invoiceMessage && !watchInvoiceMessage && (
            <span className={styles.errorMessage}>
              {errors.invoiceMessage.message}
            </span>
          )}
          <div style={{ width: '100%' }}>
            <hr />
          </div>
          <div className={styles.wrapper_buttons}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={close}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalInvoiceRefuse;
