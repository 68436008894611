import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Switch from 'react-switch';
import { Loader, SelectPicker } from 'rsuite';
import ButtonGoBack from '../../components/ButtonGoBack';
import { Details } from '../../components/Details';
import { IconEyes, IconKey } from '../../components/Details/Icons';
import { LoadingBall } from '../../components/UI/LoadingBall';
import ToolTip from '../../components/UI/ToolTip';
import { useNotification } from '../../hooks/useNotifications';
import { useAllBalancesQuery } from '../../hooks/useQuery';
import { useUser } from '../../hooks/useUser';
import {
  apiEditCompany,
  apiGetBalance,
  apiPostCompany,
} from '../../services/api';
import {
  formatMoney,
  moneyMask,
  moneyStringToNumber,
} from '../../utils/formatMoney';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { IconPen } from '../Limits/icons';
import CardAllocableDetails from './components/CardAllocableDetails';
import CardFeeDetails from './components/CardFeeDetails';
import { account_types } from './data';
import styles from './styles.module.scss';
export default function ViewCompany() {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { addNotification } = useNotification();
  const { companyId } = useParams();
  const { data: companiesData, triggerRefetch } = useAllBalancesQuery();
  const { user } = useUser();

  const [details, setDetails] = useState(false);
  const [detailsAllocable, setDetailsAllocable] = useState(false);
  const [linkedAccountFee, setLinkedAccountFee] = useState(
    company?.linked_account_fee ?? 0,
  );
  const [companyType, setCompanyType] = useState(company?.company_type);

  const [consignationFee, setConsignationFee] = useState(
    company?.consignation_fee ?? 0,
  );
  const [transferFee, setTransferFee] = useState(company?.transfer_fee ?? 0);
  const [internalTransferFee, setInternalTransferFee] = useState(
    company?.internal_transfer_fee ?? 0,
  );
  const [receiptEnabled, setReceiptEnabled] = useState(
    company?.receipt_enabled,
  );
  const [consignationEnabled, setConsignationEnabled] = useState(
    company?.consignation_enabled,
  );
  const [allocable, setAllocable] = useState(
    company?.consignation_allocable_amount,
  );
  const [liquidAmount, setLiquidAmount] = useState(
    handleReleaseLimite(
      company?.key_values?.consignation_release_liquid_amount,
    ),
  );
  const [statusSwap, setStatusSwap] = useState(
    company?.swap_account?.status_description || 'Sem dados',
  );

  useEffect(() => {
    const findCompany = async () => {
      if (!companiesData?.allBalance) await triggerRefetch();
      const company_ref = companiesData?.allBalance.find(
        (item) => (item.company_id = companyId),
      );
      if (company_ref) {
        setCompany(company_ref);
        setCompanyType(company_ref?.company_type);
        setLinkedAccountFee(company_ref?.linked_account_fee);
        setConsignationFee(company_ref?.key_values?.consignation_fee_rate);
        setTransferFee(company_ref?.transfer_fee);
        setInternalTransferFee(company_ref?.internal_transfer_fee);
        setReceiptEnabled(company_ref?.receipt_enabled);
        setConsignationEnabled(company_ref?.consignation_enabled);
        setAllocable(company_ref?.key_values?.consignation_allocable_amount);
        setStatusSwap(
          company_ref?.swap_account?.status_description || 'Sem dados',
        );
        setLiquidAmount(
          handleReleaseLimite(
            company_ref?.key_values?.consignation_release_liquid_amount,
          ),
        );
      }
    };

    [0].every((item) => user?.permission.includes(item))
      ? getCompany()
      : findCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setLinkedAccountFee(company?.linked_account_fee);
    setCompanyType(company?.company_type);
    setConsignationFee(company?.key_values?.consignation_fee_rate);
    setTransferFee(company?.transfer_fee);
    setInternalTransferFee(company?.internal_transfer_fee);
    setReceiptEnabled(company?.receipt_enabled);
    setConsignationEnabled(company?.consignation_enabled);
    setAllocable(company?.key_values?.consignation_allocable_amount);
    setStatusSwap(company?.swap_account?.status_description || 'Sem dados');
    setLiquidAmount(
      handleReleaseLimite(
        company?.key_values?.consignation_release_liquid_amount,
      ),
    );
  }, [company]);

  const getCompany = async () => {
    const { data: dataCompany } = await apiGetBalance('/company', companyId);
    setCompany(dataCompany);
    setLinkedAccountFee(dataCompany?.linked_account_fee);
    setCompanyType(dataCompany?.company_type);
    setConsignationFee(dataCompany?.key_values?.consignation_fee_rate);
    setTransferFee(dataCompany?.transfer_fee);
    setInternalTransferFee(dataCompany?.internal_transfer_fee);
    setReceiptEnabled(dataCompany?.receipt_enabled);
    setConsignationEnabled(dataCompany?.consignation_enabled);
    setAllocable(dataCompany?.key_values?.consignation_allocable_amount);
    setStatusSwap(dataCompany?.swap_account?.status_description || 'Sem dados');
    setLiquidAmount(
      handleReleaseLimite(
        dataCompany?.key_values?.consignation_release_liquid_amount,
      ),
    );
  };

  const handleSaveCompany = async () => {
    setLoading({ company: true });

    try {
      if (!isEditing) return;

      const updateCompany = !!isEditing.company_type
        ? {
            company_type: companyType,
          }
        : !!isEditing.transfer_fee
        ? {
            transfer_fee: moneyStringToNumber(transferFee),
          }
        : !!isEditing.internal_transfer_fee
        ? {
            internal_transfer_fee: moneyStringToNumber(internalTransferFee),
          }
        : !!isEditing.linked_account_fee
        ? {
            linked_account_fee: moneyStringToNumber(linkedAccountFee),
          }
        : !!isEditing.consignation_fee
        ? {
            consignation_fee_rate: consignationFee,
          }
        : !!isEditing.consignation_allocable_amount
        ? {
            consignation_allocable_amount: moneyStringToNumber(allocable),
          }
        : !!isEditing.consignation_release_liquid_amount
        ? {
            consignation_release_liquid_amount: !liquidAmount,
          }
        : { receipt_enabled: receiptEnabled };

      const { status, data } = await apiEditCompany(
        `/Company/Update`,
        updateCompany,
        companyId,
      );

      if (status === 200) {
        company.receipt_enabled = data.value.receipt_enabled;
        company.company_type = data.value.company_type;
        company.transfer_fee = data.value.transfer_fee;
        company.internal_transfer_fee = data.value.internal_transfer_fee;
        company.linked_account_fee = data.value.linked_account_fee;
        company.consignation_fee = data.value.key_values.consignation_fee_rate;
        company.consignation_allocable_amount =
          data.value.key_values.consignation_allocable_amount;
        company.consignation_release_liquid_amount = handleReleaseLimite(
          data.value.key_values.consignation_release_liquid_amount,
        );
        company.swap_account = data?.swap_account;

        setReceiptEnabled(data.value?.receipt_enabled);
        setTransferFee(data.value?.transfer_fee);
        setInternalTransferFee(data.value?.internal_transfer_feetransfer_fee);
        setLinkedAccountFee(data.value.linked_account_fee);
        setCompanyType(data.value?.company_type);
        setConsignationFee(data.value?.key_values.consignation_fee_rate);
        setAllocable(data.value.key_values?.consignation_allocable_amount);
        setStatusSwap(data?.swap_account?.status_description || 'Sem dados');
        setLiquidAmount(
          handleReleaseLimite(
            data.value.key_values.consignation_release_liquid_amount,
          ),
        );

        addNotification(
          <>Edição realizada com sucesso!</>,
          3,
          <>Sucesso</>,
          true,
        );

        setIsEditing(false);
      } else {
        console.error('Failed to update company');
      }
    } catch (error) {
      addNotification(
        <>Não foi possível concluir a edição.</>,
        2,
        'Erro',
        true,
      );

      console.error('Error updating company:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEditing.receipt_enabled) {
      setIsEditing(false);
      handleSaveCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptEnabled, consignationEnabled]);

  async function handleConsignationChange() {
    try {
      if (!consignationEnabled)
        await apiPostCompany('/company/activate/consignation', companyId);
      else await apiPostCompany('/company/deactivate/consignation', companyId);

      setConsignationEnabled((old) => !old);
      addNotification(
        <>Edição realizada com sucesso!</>,
        3,
        <>Sucesso</>,
        true,
      );

      getCompany();
    } catch (error) {
      addNotification(<>Desculpe, algo deu errado.</>, 2, <>Erro</>, true);
      console.error(error);
    } finally {
      setIsEditing(false);
    }
  }

  function handleReleaseLimite(data) {
    switch (data) {
      case true:
        return true;
      case 'True':
        return true;
      case 'true':
        return true;
      default:
        return false;
    }
  }

  function releaseLimiteChange() {
    setLiquidAmount((old) => !old);
    handleSaveCompany();
  }

  async function handleSwapUpdate(force) {
    try {
      if (force === true) {
        const { data } = await apiPostCompany(
          '/company/activate/swap?update=true',
          companyId,
        );

        if (data?.message && data?.status)
          setStatusSwap(data?.message || undefined);
        addNotification(
          <>Solicitação realizada com sucesso!</>,
          3,
          <>Sucesso</>,
          true,
        );
      } else {
        const { data } = await apiPostCompany(
          '/company/activate/swap?update=false',
          companyId,
        );

        setStatusSwap(data?.message || undefined);
        addNotification(
          <>O status atual é: {data?.message || 'Não encontrado'}</>,
          3,
          <>Status Swap</>,
          true,
        );
      }
    } catch (error) {
      addNotification(<>Desculpe, algo deu errado.</>, 2, <>Erro</>, true);
      console.error(error);
    }
  }

  return (
    <>
      <CardFeeDetails
        companyId={companyId}
        details={details}
        setDetails={setDetails}
      />
      <CardAllocableDetails
        companyId={companyId}
        details={detailsAllocable}
        setDetails={setDetailsAllocable}
      />
      <ul className={styles.container_box}>
        <div className={styles.menu}>
          {!company ? (
            <LoadingBall />
          ) : (
            <>
              <ButtonGoBack backRoute="/menu/balances" />
              <header className={styles.header}>
                <h1>{company?.fantasy_name} </h1>
              </header>

              <div className={styles.document}>
                <strong>Documento</strong>
                <p> {company?.document}</p>
              </div>
              {company?.contact !== company?.accountable && (
                <div className={styles.contact}>
                  <strong>Contato</strong>
                  {company?.contact !== company?.accountable && (
                    <p>{company?.contact}</p>
                  )}

                  {company?.contact !== company?.accountable && (
                    <p> {formatPhoneNumber(company?.phone)}</p>
                  )}
                </div>
              )}
              <div className={styles.info_}>
                <div className={styles.info}>
                  <h3>Dados da conta </h3>
                  {company?.accountable && (
                    <li>
                      <strong>Responsável:</strong>
                      <p>{company?.accountable}</p>
                    </li>
                  )}
                  {company?.email && (
                    <li>
                      <strong>Email:</strong>
                      <p> {company?.email}</p>
                    </li>
                  )}
                  {company?.cnpj && (
                    <li>
                      <strong>CNPJ:</strong>
                      <p> {company?.cnpj}</p>
                    </li>
                  )}
                  {company?.accountable_cpf && (
                    <li>
                      <strong>CPF Responsável:</strong>
                      <p> {company?.accountable_cpf}</p>
                    </li>
                  )}
                  {company?.cpf && (
                    <li>
                      <strong>CNPJ:</strong>
                      <p> {company?.cpf}</p>
                    </li>
                  )}
                  {[0].every((item) => user?.permission.includes(item)) &&
                    !isEditing?.company_type && (
                      <li>
                        <strong>Tipo de Conta:</strong>
                        <p> {account_types(company?.company_type).label}</p>
                        <button
                          className={styles.button__edit_}
                          onClick={() => setIsEditing({ company_type: true })}
                        >
                          <IconPen color={'var(--gray-500)'} size={'1.2rem'} />
                        </button>
                      </li>
                    )}
                  {isEditing?.company_type && (
                    <li
                      style={{
                        marginTop: '1rem',
                        padding: '0 1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <SelectPicker
                        data={[
                          { label: 'Motorista', value: 'Driver' },
                          { label: 'Transportadora', value: 'Currier' },
                        ]}
                        placeholder="Tipo de Conta"
                        style={{ width: 224 }}
                        name="status"
                        onChange={(value) => setCompanyType(value)}
                        disabled={loading}
                        value={companyType}
                        searchable={false}
                      />

                      <div className={styles.wrapper__button}>
                        {loading?.company ? (
                          <Loader />
                        ) : (
                          <>
                            <button
                              className={styles.button__plus}
                              onClick={handleSaveCompany}
                            >
                              Ok
                            </button>
                            <button
                              className={styles.button__plus_}
                              onClick={() => {
                                setLinkedAccountFee(company?.company_type);
                                setIsEditing(false);
                              }}
                            >
                              Cancelar
                            </button>
                          </>
                        )}
                      </div>
                    </li>
                  )}
                </div>
                <div className={styles.info}>
                  <h3>Endereço </h3>
                  {company?.state && (
                    <li>
                      <strong>Estado:</strong>
                      <p>{company?.state}</p>
                    </li>
                  )}
                  {company?.city && (
                    <li>
                      <strong>Cidade:</strong>
                      <p>{company?.city}</p>
                    </li>
                  )}

                  {company?.neighborhood && (
                    <li>
                      <strong>Bairro:</strong>
                      <p> {company?.neighborhood}</p>
                    </li>
                  )}
                  {company?.street && (
                    <li>
                      <strong>Rua:</strong>
                      <p> {company?.street}</p>
                    </li>
                  )}
                  {company?.street_number && (
                    <li>
                      <strong>Numero:</strong>
                      <p> {company?.street_number}</p>
                    </li>
                  )}
                  {company?.street_complement && (
                    <li>
                      <strong>Complemento:</strong>
                      <p> {company?.street_complement}</p>
                    </li>
                  )}
                  {company?.zipcode && (
                    <li>
                      <strong>Cep:</strong>
                      <p> {company?.zipcode}</p>
                    </li>
                  )}
                </div>
                {[0].every((item) => user?.permission.includes(item)) && (
                  <div className={styles.info}>
                    <h3>Taxas da conta </h3>
                    <li>
                      <strong>Taxa de Pagamentos / Boletos:</strong>
                      {isEditing.transfer_fee ? (
                        <>
                          <input
                            type="text"
                            className={styles.input}
                            value={moneyMask(transferFee)}
                            onChange={(e) =>
                              setTransferFee(moneyMask(e.target.value))
                            }
                          />
                          <div className={styles.wrapper__button}>
                            {loading?.company ? (
                              <Loader />
                            ) : (
                              <>
                                <button
                                  className={styles.button__plus}
                                  onClick={handleSaveCompany}
                                >
                                  Ok
                                </button>
                                <button
                                  className={styles.button__plus_}
                                  onClick={() => {
                                    setTransferFee(company?.transfer_fee);
                                    setIsEditing(false);
                                  }}
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <p>{formatMoney(transferFee ?? 0)}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() => setIsEditing({ transfer_fee: true })}
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>

                    <li>
                      <strong>Transferências Internas:</strong>
                      {isEditing.internal_transfer_fee ? (
                        <>
                          <input
                            type="text"
                            className={styles.input}
                            value={moneyMask(internalTransferFee)}
                            onChange={(e) =>
                              setInternalTransferFee(moneyMask(e.target.value))
                            }
                          />
                          <div className={styles.wrapper__button}>
                            {loading?.company ? (
                              <Loader />
                            ) : (
                              <>
                                <button
                                  className={styles.button__plus}
                                  onClick={handleSaveCompany}
                                >
                                  Ok
                                </button>
                                <button
                                  className={styles.button__plus_}
                                  onClick={() => {
                                    setInternalTransferFee(
                                      company?.internal_transfer_fee,
                                    );
                                    setIsEditing(false);
                                  }}
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <p>{formatMoney(internalTransferFee ?? 0)}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({ internal_transfer_fee: true })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>

                    <li>
                      <strong>Taxa de antecipação: (%)</strong>
                      {isEditing.consignation_fee ? (
                        <>
                          <input
                            type="text"
                            className={styles.input}
                            value={consignationFee}
                            onChange={(e) => setConsignationFee(e.target.value)}
                          />
                          <div className={styles.wrapper__button}>
                            {loading?.company ? (
                              <Loader />
                            ) : (
                              <>
                                <button
                                  className={styles.button__plus}
                                  onClick={handleSaveCompany}
                                >
                                  Ok
                                </button>
                                <button
                                  className={styles.button__plus_}
                                  onClick={() => {
                                    setConsignationFee(
                                      company?.key_values
                                        ?.consignation_fee_rate,
                                    );
                                    setIsEditing(false);
                                  }}
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <p>{consignationFee ?? 0.0}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({ consignation_fee: true })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                          <button
                            className={styles.button__edit_}
                            onClick={() => setDetails(true)}
                          >
                            <IconEyes
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>

                    <li>
                      <strong>Alocação de antecipação:</strong>
                      {isEditing.consignation_allocable_amount ? (
                        <>
                          <input
                            type="text"
                            className={styles.input}
                            value={moneyMask(allocable)}
                            onChange={(e) =>
                              setAllocable(moneyMask(e.target.value))
                            }
                          />
                          <div className={styles.wrapper__button}>
                            {loading?.company ? (
                              <Loader />
                            ) : (
                              <>
                                <button
                                  className={styles.button__plus}
                                  onClick={handleSaveCompany}
                                >
                                  Ok
                                </button>
                                <button
                                  className={styles.button__plus_}
                                  onClick={() => {
                                    setAllocable(
                                      company?.key_values
                                        ?.consignation_allocable_amount,
                                    );
                                    setIsEditing(false);
                                  }}
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <p>{formatMoney(allocable ?? 0)}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({
                                consignation_allocable_amount: true,
                              })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                          <button
                            className={styles.button__edit_}
                            onClick={() => setDetailsAllocable(true)}
                          >
                            <IconEyes
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>

                    <li>
                      <strong>Liberar limite de antecipação:</strong>
                      {isEditing.consignation_release_liquid_amount ? (
                        <>
                          {[0].every((item) =>
                            user?.permission.includes(item),
                          ) && (
                            <li className={styles.wrapper__Switch}>
                              <ToolTip
                                text={
                                  liquidAmount === true ||
                                  linkedAccountFee === 'True'
                                    ? 'Ativado'
                                    : 'Desativado'
                                }
                              >
                                <Switch
                                  onChange={() => {
                                    releaseLimiteChange();
                                  }}
                                  checked={liquidAmount}
                                  checkedIcon={true}
                                  uncheckedIcon={true}
                                  height={20}
                                  width={47}
                                  handleDiameter={14}
                                  offColor="#e6e6e6"
                                  onColor="#71AB69"
                                  offHandleColor="#e63a0f"
                                  onHandleColor="#ffffff"
                                  disabled={loading}
                                />
                              </ToolTip>
                              <button
                                className={styles.button__plus_}
                                onClick={() => {
                                  setLiquidAmount(
                                    company?.consignation_release_liquid_amount,
                                  );
                                  setIsEditing(false);
                                }}
                              >
                                Cancelar
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          <p>{liquidAmount ? 'Sim' : 'Não'}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({
                                consignation_release_liquid_amount: true,
                              })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>

                    <li>
                      <strong>Conta ativa:</strong>
                      {isEditing.linked_account_fee ? (
                        <>
                          <input
                            type="text"
                            className={styles.input}
                            value={moneyMask(linkedAccountFee)}
                            onChange={(e) =>
                              setLinkedAccountFee(moneyMask(e.target.value))
                            }
                          />
                          <div className={styles.wrapper__button}>
                            {loading?.company ? (
                              <Loader />
                            ) : (
                              <>
                                <button
                                  className={styles.button__plus}
                                  onClick={handleSaveCompany}
                                >
                                  Ok
                                </button>
                                <button
                                  className={styles.button__plus_}
                                  onClick={() => {
                                    setLinkedAccountFee(
                                      company?.linked_account_fee,
                                    );
                                    setIsEditing(false);
                                  }}
                                >
                                  Cancelar
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <p>{formatMoney(linkedAccountFee ?? 0)}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({ linked_account_fee: true })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>
                  </div>
                )}
                {[0].every((item) => user?.permission.includes(item)) && (
                  <div className={styles.info}>
                    <h3>Produtos </h3>
                    <li>
                      <strong>Lançamento de Antecipação:</strong>
                      {isEditing.consignation_enabled ? (
                        <>
                          {[0].every((item) =>
                            user?.permission.includes(item),
                          ) && (
                            <li className={styles.wrapper__Switch}>
                              <ToolTip
                                text={
                                  consignationEnabled ? 'Ativado' : 'Desativado'
                                }
                              >
                                <Switch
                                  onChange={() => {
                                    setIsEditing({
                                      consignation_enabled: true,
                                    });
                                    handleConsignationChange();
                                  }}
                                  checked={consignationEnabled}
                                  checkedIcon={true}
                                  uncheckedIcon={true}
                                  height={20}
                                  width={47}
                                  handleDiameter={14}
                                  offColor="#e6e6e6"
                                  onColor="#71AB69"
                                  offHandleColor="#e63a0f"
                                  onHandleColor="#ffffff"
                                  disabled={loading}
                                />
                              </ToolTip>
                              <button
                                className={styles.button__plus_}
                                onClick={() => {
                                  setLinkedAccountFee(
                                    company?.consignation_enabled,
                                  );
                                  setIsEditing(false);
                                }}
                              >
                                Cancelar
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          <p>{consignationEnabled === true ? 'Sim' : 'Não'}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({ consignation_enabled: true })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>
                    <li>
                      <strong>Gestor de notas:</strong>
                      {isEditing.receipt_enabled ? (
                        <>
                          {[0].every((item) =>
                            user?.permission.includes(item),
                          ) && (
                            <li className={styles.wrapper__Switch}>
                              <ToolTip
                                text={receiptEnabled ? 'Ativado' : 'Desativado'}
                              >
                                <Switch
                                  onChange={() => {
                                    setReceiptEnabled((old) => !old);
                                    setIsEditing({ receipt_enabled: true });
                                  }}
                                  checked={receiptEnabled}
                                  checkedIcon={true}
                                  uncheckedIcon={true}
                                  height={20}
                                  width={47}
                                  handleDiameter={14}
                                  offColor="#e6e6e6"
                                  onColor="#71AB69"
                                  offHandleColor="#e63a0f"
                                  onHandleColor="#ffffff"
                                  disabled={loading}
                                />
                              </ToolTip>
                              <button
                                className={styles.button__plus_}
                                onClick={() => {
                                  setLinkedAccountFee(company?.receipt_enabled);
                                  setIsEditing(false);
                                }}
                              >
                                Cancelar
                              </button>
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          <p>{receiptEnabled === true ? 'Sim' : 'Não'}</p>
                          <button
                            className={styles.button__edit_}
                            onClick={() =>
                              setIsEditing({ receipt_enabled: true })
                            }
                          >
                            <IconPen
                              color={'var(--gray-500)'}
                              size={'1.2rem'}
                            />
                          </button>
                        </>
                      )}
                    </li>
                    <li>
                      <strong>Status Swap:</strong>
                      <>
                        <p>{statusSwap || 'Sem dados'}</p>
                        <button
                          type="button"
                          className={styles.getButton}
                          disabled={loading}
                          onClick={() => handleSwapUpdate(false)}
                        >
                          Consultar atualização
                        </button>
                        <button
                          type="button"
                          className={styles.forceButton}
                          disabled={loading}
                          onClick={() => handleSwapUpdate(true)}
                        >
                          Forçar atualização
                        </button>
                      </>
                    </li>
                  </div>
                )}
              </div>

              <div className={styles.document}>
                {company?.default_pix_key ? (
                  <Details
                    maxWidth={'40rem'}
                    Icon={IconKey}
                    title={'Chave Pix'}
                    value={company?.default_pix_key}
                    copy={true}
                  />
                ) : (
                  <>
                    <strong>Chave Pix</strong>
                    <p>---</p>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </ul>
    </>
  );
}
