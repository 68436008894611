export function formatMoney(amount, currencyDisplay) {
  if (amount === null || amount === undefined || isNaN(amount)) return '';

  const options = {
    style: 'currency',
    currency: 'BRL',
  };

  if (currencyDisplay === 'code') {
    options.currencyDisplay = 'code';
  }
  return new Intl.NumberFormat('pt-BR', options)
    .format(amount)
    .replace('BRL', '')
    .trim();
}

export function moneyMask(value) {
  if (!value) return '';

  if (typeof value === 'number') {
    return formatMoney(value);
  } else {
    return formatMoney(moneyStringToNumber(value));
  }
}

export function moneyStringToNumber(value) {
  return Number(value.replace(/\D/g, '')) / 100;
}
