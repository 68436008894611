import { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';
import FormsCustomers from '../../components/Forms/FormsCustomers';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { ButtonFilterSelect } from '../../components/UI/ButtonFilterSelect';
import { InputSearch } from '../../components/UI/InputSearch';
import { Loading } from '../../components/UI/Loading';
import { useCustomers } from '../../hooks/useCustomers';
import { usePagination } from '../../hooks/usePagination';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import { IconPlus } from '../Collaborators/Components/CardCollaborator/Components/TagInput/icon';
import CardCustomers from './Components/CardCustomers';
import styles from './styles.module.scss';
import { useCustomerListDataQuery } from '../../hooks/useQuery';
const Customers = () => {
  const {
    fetchCustomers,
    fetchCapabilities,
    fetchProfiles,
    isNewCustomer,
    setIsNewCustomer,
  } = useCustomers();
  const { user } = useUser();

  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState();
  const [isActive, setIsActive] = useState(true);
  const [enable, setEnable] = useState(0);
  const [disabled, setDisabled] = useState(0);
  // eslint-disable-next-line
  const [company, setCompany] = useState(user.company);
  const { data: customers, isFetching: loading } = useCustomerListDataQuery();
  useEffect(() => {
    fetchCustomers();
    if ([50].every((item) => user?.permission.includes(item))) {
      fetchCapabilities();
      fetchProfiles();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customers) {
      setDisabled(customers.filter((item) => item.is_active === false).length);
      setEnable(customers.filter((item) => item.is_active === true).length);
    }
  }, [customers]);

  function handleNewCustomer() {
    setIsNewCustomer(true);
    setEdit(false);
  }

  const list = customers
    ? customers
        .filter((item) => {
          if (search) return item;
          return item.is_active === isActive;
        })
        .filter((item) => {
          if (!search) return item;
          return (
            lower(item?.name).includes(lower(search)) ||
            lower(item['document']).includes(lower(search))
          );
        })
    : [];
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    50,
    '/menu/customers/',
  );
  return (
    <div className={styles.container}>
      <div className={styles.header_style}>
        <h1 className={styles.title_style}>Usuários Plataforma</h1>

        {[7].every((item) => user?.permission.includes(item)) && (
          <button className={styles.button__} onClick={handleNewCustomer}>
            <IconPlus color="var(--white)" size="1.5rem" /> Novo
          </button>
        )}
      </div>
      <div className={styles.wrapper__button}>
        <InputSearch
          label="Pesquisar por nome ou documento"
          type="text"
          setValue={setSearch}
          value={search}
          Icon={RiSearch2Line}
          width="50%"
        />
      </div>
      <div className={styles.isActive}>
        <div className={styles.wrapper_button_isActive}>
          <ButtonFilterSelect
            isActive={isActive}
            account={enable}
            setIsActive={setIsActive}
            setCurrentPage={setCurrentPage}
            title="Ativo"
            value={true}
          />
          <ButtonFilterSelect
            isActive={isActive}
            account={disabled}
            setIsActive={setIsActive}
            setCurrentPage={setCurrentPage}
            title="Desativados"
            value={false}
          />
        </div>
        <div className={styles.container_arrow_box}>
          <PaginationArrow
            pages={pages}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
      {isNewCustomer && (
        <FormsCustomers {...edit} alter={!!edit} company={company} />
      )}

      {currentItens.length >= 1 ? (
        currentItens.map((customer) => (
          <CardCustomers
            setEdit={setEdit}
            key={customer.customer_id}
            {...customer}
            company={company}
          />
        ))
      ) : (
        <div className={styles.listLoader}>
          {loading ? (
            <Loading />
          ) : (
            <h5>
              <RiSearch2Line size={24} color="#222" /> Nenhum resultado
              encontrado!
            </h5>
          )}
        </div>
      )}
    </div>
  );
};

export default Customers;
