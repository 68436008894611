import styled from 'styled-components';

const StyledSvg = styled.svg`
  @keyframes animate-svg-trans-pay-stroke-1 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-money-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(236, 253, 243);
    }
  }

  .svg-elem-1 {
    animation: animate-svg-trans-pay-stroke-1 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-money-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-2 {
    0% {
      stroke-dashoffset: 272.23895263671875px;
      stroke-dasharray: 272.23895263671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 272.23895263671875px;
    }
  }

  @keyframes animate-money-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: url('#paint0_linear_4129_14704');
    }
  }

  .svg-elem-2 {
    animation: animate-svg-trans-pay-stroke-2 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
      animate-money-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-3 {
    0% {
      stroke-dashoffset: 128.23894500732422px;
      stroke-dasharray: 128.23894500732422px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 128.23894500732422px;
    }
  }

  .svg-elem-3 {
    animation: animate-svg-trans-pay-stroke-3 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
      animate-money-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }

  @keyframes animate-svg-trans-pay-stroke-4 {
    0% {
      stroke-dashoffset: 72.27583312988281px;
      stroke-dasharray: 72.27583312988281px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 72.27583312988281px;
    }
  }

  @keyframes animate-money-svg-fill-4 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-4 {
    animation: animate-svg-trans-pay-stroke-4 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
      animate-money-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
        both;
  }

  @keyframes animate-svg-trans-pay-stroke-5 {
    0% {
      stroke-dashoffset: 35.49003219604492px;
      stroke-dasharray: 35.49003219604492px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 35.49003219604492px;
    }
  }

  @keyframes animate-money-svg-fill-5 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-5 {
    animation: animate-svg-trans-pay-stroke-5 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
      animate-money-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        1.2000000000000002s both;
  }

  @keyframes animate-svg-trans-pay-stroke-6 {
    0% {
      stroke-dashoffset: 148.59646606445312px;
      stroke-dasharray: 148.59646606445312px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 148.59646606445312px;
    }
  }

  @keyframes animate-money-svg-fill-6 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(18, 183, 106);
    }
  }

  .svg-elem-6 {
    animation: animate-svg-trans-pay-stroke-6 1s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-money-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
        both;
  }
`;

const IconApprovedInvoice = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height="79"
      fill="none"
      viewBox="0 0 134 79"
    >
      <path
        fill="url(#paint0_linear_490_1584)"
        d="M132 12.084c-21.121 1.76-22.429 43.897-43.333 48.75-17.239 4.001-25.968-18.714-43.334-16.25C26.497 47.256 20.47 70.927 2 77.084h130z"
        className="svg-elem-3"
      ></path>
      <path
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.031"
        d="M2 77.084c18.47-6.157 24.497-29.828 43.333-32.5C62.7 42.12 71.428 64.835 88.667 60.834c20.904-4.853 22.212-46.99 43.333-48.75"
        className="svg-elem-2"
      ></path>
      <path
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.858"
        d="M98.262 27.838v-5.001a6.43 6.43 0 0 0-6.431-6.431h-2.858a2.144 2.144 0 0 1-2.144-2.144v-2.858a6.43 6.43 0 0 0-6.43-6.43h-3.573m4.287 0h-9.289c-1.183 0-2.143.96-2.143 2.143v32.868c0 1.183.96 2.144 2.143 2.144h24.294c1.183 0 2.144-.96 2.144-2.144V22.122A17.15 17.15 0 0 0 81.113 4.973"
        className="svg-elem-3"
      ></path>
      <path
        fill="#12B76A"
        fillRule="evenodd"
        d="M74.445 33.079c0-1.053.853-1.906 1.906-1.906h15.243c1.052 0 1.905.853 1.905 1.906v3.81a1.905 1.905 0 0 1-1.905 1.906H76.35a1.905 1.905 0 0 1-1.906-1.906zm13.939 3.914c-.799 0-1.359-.58-1.359-1.412 0-.843.55-1.424 1.337-1.424.804 0 1.315.538 1.315 1.375v.2l-2.021.006c.049.473.299.712.739.712.364 0 .603-.141.679-.397h.614c-.114.587-.603.94-1.304.94m-.016-2.293c-.392 0-.63.212-.696.614h1.348c0-.369-.256-.614-.652-.614m-3.446.478v.587h1.75v-.587zm-.538.12h-1.478v1.625h-.695V32.95h2.456v.64h-1.76v1.082h1.477zm-6.128 1.625h.684v-2.815l1.87 2.815h.684V32.95h-.684v2.815l-1.864-2.815h-.69z"
        clipRule="evenodd"
        className="svg-elem-4"
      ></path>
      <path
        fill="#10B981"
        d="M83.864 28.351a4.7 4.7 0 0 1-4.039-2.298 4.6 4.6 0 0 1-.686-2.428c0-1.452.646-2.795 1.77-3.69a4.77 4.77 0 0 1 2.955-1.035 4.73 4.73 0 0 1 4.726 4.725c0 .866-.239 1.712-.686 2.438a4.2 4.2 0 0 1-.945 1.114c-.826.756-1.93 1.174-3.095 1.174m0-7.96c-.736 0-1.432.25-2.02.717a3.207 3.207 0 0 0-.745 4.179 3.17 3.17 0 0 0 2.765 1.572c.786 0 1.542-.289 2.12-.806.258-.219.477-.478.636-.756a3.13 3.13 0 0 0 .478-1.672 3.24 3.24 0 0 0-3.234-3.233"
        className="svg-elem-5"
      ></path>
      <path
        fill="#10B981"
        d="M83.297 25.356a.74.74 0 0 1-.527-.218l-.985-.985a.75.75 0 0 1 0-1.055.75.75 0 0 1 1.054 0l.478.478 1.592-1.473a.75.75 0 0 1 1.054.04.75.75 0 0 1-.04 1.055l-2.119 1.96a.78.78 0 0 1-.507.198"
        className="svg-elem-6"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_490_1584"
          x1="67"
          x2="67"
          y1="54.218"
          y2="77.084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECFDF3"></stop>
          <stop offset="1" stopColor="#ECFDF3" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};

export { IconApprovedInvoice };
