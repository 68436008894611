import { LogActions, LogTypes } from '../../domain/emuns/LogActions';
import { LogEntry } from '../../domain/entities/LogEntry';
import { getValueFromLocalStorage } from '../../hooks/services/Localstorage';
import { logService } from '../../services/LogService';

export const sendLog = async (
  action: LogActions,
  logType: LogTypes,
  additionalData: Record<string, any> = {},
) => {
  const user = await getValueFromLocalStorage('user', '');

  if (!user) {
    console.error('Usuário não encontrado');
    return;
  }

  let formattedData: Record<string, any> = { ...additionalData };

  for (const key of Object.keys(formattedData)) {
    const value = formattedData[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const transformedObject: Record<string, any> = {};

      for (const [innerKey, innerValue] of Object.entries(value)) {
        transformedObject[`${key}_${innerKey}`] = innerValue;
      }

      formattedData = {
        ...formattedData,
        ...transformedObject,
      };

      delete formattedData[key];
    }
  }

  const logEntry: LogEntry = {
    time: new Date().toISOString(),
    id: crypto.randomUUID(),
    data: {
      action,
      type: logType,
      customer_id: user.customer_id,
      company_id: user.company_id,
      email: user.email,
      first_name: user.firstname,
      last_name: user.lastname,
      document: user.document,
      ...formattedData,
    },
  };

  try {
    await logService.sendLog(logEntry);
  } catch (error) {
    console.error('Erro ao registrar log:', error.message);
    throw error;
  }
};
