import styles from './styles.module.scss';
export const InputSearch = ({
  label,
  type,
  setValue,
  value,
  Icon,
  width = 'auto',
  disabled = false,
}) => {
  return (
    <div className={styles.wrapper__input} style={{ width }}>
      <input
        disabled={disabled}
        type={type}
        onChange={({ target }) => setValue(target.value)}
      />
      <div className={!!value ? `${styles.isValue}` : `${styles.label}`}>
        {label}
      </div>
      {Icon && <Icon className={styles.icon} size="1.125rem" />}
    </div>
  );
};
