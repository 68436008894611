import React, { useContext, useState } from 'react';
import { StoreContext } from '../../../../data/storeContext';
import { useNotification } from '../../../../hooks/useNotifications';
import { exportExcelInvoices } from '../../utils/exportExcel';
import exportInvoices from '../../utils/exportInvoice';
import {
  IconAction,
  IconCancel,
  IconCheck,
  IconExport,
  IconExportInvoices,
} from './icons';
import styles from './styles.module.scss';
import { Loader } from 'rsuite';

export function ButtonMenuActions({
  listSelect,
  filterList,
  invoices,
  handleReceiptApprove,
  handleReceiptRefuse,
}) {
  const [active, setActive] = useState(false);
  const count_listSelect = listSelect.length;
  const { addNotification } = useNotification();
  const { setInvoiceRefuse } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const selectedInvoices = !!count_listSelect
    ? invoices.filter((v) => listSelect.includes(v?.key))
    : invoices;
  const [progress, setProgress] = useState({
    successCount: 0,
    errorCount: 0,
    current: 0,
    total: selectedInvoices.length,
  });
  async function handleExportInvoices() {
    try {
      const { successCount, errorCount } = await exportInvoices(
        selectedInvoices,
        setProgress,
        progress,
        setLoading,
      );

      addNotification(
        <>
          Exportação finalizada! <br />
          Sucessos: {successCount}, Erros: {errorCount}.
        </>,
        3,
        'Processamento de exportação',
        true,
      );
    } catch (error) {
      addNotification(<>Erro ao exportar notas!</>, 2, 'Desculpe', true);
    } finally {
      setLoading({ export: false });
      setProgress({
        ...progress,
        current: 0,
      });
    }
  }

  function handleOpenModalRefuse() {
    setInvoiceRefuse({
      open: true,
      handle: handleReceiptRefuse,
      reasons: [
        ...new Set(
          filterList.map((i) =>
            i?.message
              ? i?.message.charAt(0).toUpperCase() + i?.message.slice(1)
              : undefined,
          ),
        ),
      ],
    });
  }

  return (
    <div className={styles.container} onClick={() => setActive(!active)}>
      {loading?.export ? (
        <>
          {Math.round((progress.current / progress.total) * 100)}% <Loader />
        </>
      ) : (
        <>
          Ações <IconAction size={'1.8rem'} />
        </>
      )}
      {active && (
        <div className={styles.menu}>
          {count_listSelect > 0 && (
            <>
              <button
                className={styles.button_plus}
                onClick={() => handleReceiptApprove()}
                disabled={
                  filterList.filter(
                    (i) =>
                      (i?.key.includes(listSelect) && i?.status?.id === 1) ||
                      (i?.key.includes(listSelect) && i?.status?.id === 6),
                  ).length
                    ? false
                    : true
                }
              >
                <IconCheck size={'1.5rem'} />
                Aprovar ({count_listSelect})
              </button>
              <button
                className={styles.button_plus}
                onClick={() => handleOpenModalRefuse()}
                disabled={
                  filterList.filter(
                    (i) =>
                      (i?.key.includes(listSelect) && i?.status?.id === 1) ||
                      (i?.key.includes(listSelect) && i?.status?.id === 6),
                  ).length
                    ? false
                    : true
                }
              >
                <IconCancel size={'1.5rem'} />
                Recusar ({count_listSelect})
              </button>
            </>
          )}
          <button
            className={styles.button_plus}
            onClick={() => exportExcelInvoices(selectedInvoices)}
          >
            <IconExport size={'1.5rem'} />
            {!!count_listSelect
              ? `Relatório Excel (${count_listSelect})`
              : 'Relatório Excel (Período)'}
          </button>
          {count_listSelect > 0 && (
            <>
              <button
                className={styles.button_plus}
                onClick={() => handleExportInvoices()}
              >
                <IconExportInvoices size={'1.5rem'} color="#666666" />
                Exportar Notas
              </button>
            </>
          )}
        </div>
      )}
      {active && (
        <div onClick={() => setActive(false)} className={styles.overlay}></div>
      )}
    </div>
  );
}
