export enum LogActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  TRANSACTION = 'TRANSACTION',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  RESET_PASSWORD_CUSTOMER = 'RESET_PASSWORD_CUSTOMER',
  REMOVE_CUSTOMER = 'REMOVE_CUSTOMER',
  UPDATE_CUSTOMER_CAPABILITIES = 'UPDATE_CUSTOMER_CAPABILITIES',
  EXPORT_EXTRACT_PDF = 'EXPORT_EXTRACT_PDF',
  EXPORT_EXTRACT_XLS = 'EXPORT_EXTRACT_XLSX',
  EXPORT_EXTRACT_OFX = 'EXPORT_EXTRACT_OFX',
  ACTIVE_CUSTOMER = 'ACTIVE_CUSTOMER',
  DISABLED_CUSTOMER = 'DISABLED_CUSTOMER',
}

export enum LogTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
