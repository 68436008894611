export const account_types = (value) => {
  const types = [
    {
      value: 'Currier',
      label: 'Cliente',
    },
    {
      value: 'Driver',
      label: 'Motorista',
    },
    {
      value: 'OUTROS',
      label: 'Outros',
    },
    {
      value: 'OxPay',
      label: 'OxPay',
    },
    {
      value: 'oxpay',
      label: 'OxPay',
    },
    {
      value: 'Interno',
      label: 'Colaborador Ox',
    },
  ];
  return (
    types.find((item) => item.value === value) ?? {
      value: 'Indefinido',
      label: 'Conta',
    }
  );
};
