import React, { useEffect, useState } from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { v4 as uid } from 'uuid';
import ButtonGoBack from '../../components/ButtonGoBack';
import { useCustomers } from '../../hooks/useCustomers';
import { useNotification } from '../../hooks/useNotifications';
import useSurveys from '../../hooks/useSurveys';
import { useTags } from '../../hooks/useTags';
import { useUser } from '../../hooks/useUser';
import { apiPost } from '../../services/api';
import { formatDocument } from '../../utils/formatDocument';
import { formatMoney } from '../../utils/formatMoney';
import { lower } from '../../utils/lower';
import { ButtonMenuDownload } from './ButtonMenuDownload';
import ExcelReader from './ExcelReader';
import AddUserForm from './Forms/AddUserForm';
import EditUserForm from './Forms/EditUserForm';
import { IconList, IconPlus } from './Icons';
import ProgressBar from './ProgressBar';
import styles from './styles.module.scss';
import UserTable from './UserTable';
import {
  findDuplicates,
  formatErrorResponse,
  formatType,
  formatUserTypes,
} from './utils/format';
function capitalizeName(name) {
  const nameParts = name.toLowerCase().split(' ');

  const capitalizedParts = nameParts.map((part) => {
    if (part.length > 0) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    }
    return part;
  });

  return capitalizedParts.join(' ');
}

export function GroupOnboarding() {
  const { setExpend } = useUser();
  const initialFormState = {
    id: null,
    name: '',
    email: '',
    cpf: '',
    phone_number: '',
  };
  const { AddedTags } = useTags();
  const { usersCollaborator, setUsersCollaborator } = useCustomers();
  const [editing, setEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [, setRes] = useState([]);
  const [sum, setSum] = useState(0);
  const [type, setType] = useState(false);
  const { addNotification } = useNotification();
  const [listDeleteUser, setListDeleteUser] = useState([]);
  const usersReady = usersCollaborator.filter((user) => user.type.id === 4);
  const [firstClick, setFirstClick] = useState(true);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [listActive, setListActive] = useState([]);

  const { triggerSurvey } = useSurveys();

  const findSurvey = async (id) => {
    await triggerSurvey(id);
  };
  useEffect(() => {
    findSurvey('11');
    // eslint-disable-next-line
  }, []);

  async function handleListActives() {
    try {
      setLoading(true);
      await queryClient.invalidateQueries(['ConsignableCollaborator']);
      const info = await queryClient.getQueryData(['ConsignableCollaborator']);
      const data = info
        .filter((item) => item.status?.id === 3)
        .map((item) => {
          return {
            document: formatDocument(item?.document),
            cpf: formatDocument(item?.cpf),
            cnpj: item?.cnpj ? formatDocument(item?.cnpj) : false,
          };
        });
      setListActive(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setExpend(false);
    handleListActives();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    setRes([]);
    var data = [];
    const makeRequest = async (user, index) => {
      if (index >= usersReady.length) {
        setLoading(false);
        return;
      }
      const comodato = user?.COMODATO
        ? {
            COMODATO: user?.COMODATO,
          }
        : null;

      const agent = user?.NOME_DO_AGENCIADOR
        ? {
            NOME_DO_AGENCIADOR: user?.NOME_DO_AGENCIADOR,
            DOCUMENTO_DO_AGENCIADOR: user.DOCUMENTO_DO_AGENCIADOR,
          }
        : null;
      const service = user?.VALOR_DO_SERVICO
        ? {
            VALOR_DO_SERVICO: formatMoney(user?.VALOR_DO_SERVICO),
          }
        : null;
      const daily = user?.VALOR_DA_DIARIA
        ? {
            VALOR_DA_DIARIA: formatMoney(user?.VALOR_DA_DIARIA),
            VALOR_DA_DIARIA_AJUDANTE: formatMoney(
              user.VALOR_DA_DIARIA_AJUDANTE,
            ),
          }
        : null;

      const file = user?.file
        ? {
            file: user?.file,
          }
        : null;

      const additional_data = Object.assign(
        {},
        ...(agent ? [agent] : []),
        ...(service ? [service] : []),
        ...(daily ? [daily] : []),
        ...(comodato ? [comodato] : []),
        ...(file ? [file] : []),
      );
      setSum(index);
      let tags = user?.tags ?? false;
      delete user?.tags;

      try {
        const onboardingStart = {
          cpf: user.cpf,
          ...(user.cnpj && { cnpj: user.cnpj }),
          phone_number: user.phone_number,
          email: user.email,
          description: user?.description,
          name: capitalizeName(user.name),
          type: Number(3),
          ...(user.contract_type && {
            contract_type: lower(user.contract_type.trim()),
          }),
          additional_data,
        };

        const response = await apiPost(`/Onboarding`, { ...onboardingStart });

        const { status } = response;

        if (status && tags) {
          await AddedTags([response?.data?.value?.key], tags);
        }

        data.push({
          ...user,
          type: formatType(2),
          response: response?.response?.data?.error?.code,
          contract_type: user.contract_type,
          ...(tags.length > 0 && { tags }),
        });

        await makeRequest(usersReady[index + 1], index + 1);
      } catch (error) {
        data.push({
          ...user,
          type: formatType(3),
          response: formatErrorResponse(error?.response?.data?.error?.code),
          contract_type: user.contract_type,
          ...(tags.length > 0 && { tags }),
        });

        if (index + 1 >= usersReady.length) {
          setLoading(false);
          return;
        }
        await makeRequest(usersReady[index + 1], index + 1);

        setLoading(false);
      } finally {
        await findSurvey('12');
      }
    };

    if (usersReady.length > 0) {
      await makeRequest(usersReady[0], 0);
    } else {
      setLoading(false);
    }
    addNotification(
      <>
        Colaboradores enviado: <br /> Total de {data.length} tentativas <br />
        {data && data.filter((item) => item?.type?.id === 2).length} tentativas
        de cadastros com sucesso
      </>,
      3,
      'Colaborador ',
      true,
    );

    const listDeleteUsersData = data.filter((user) => {
      return user.type.id === 3 && user.id;
    });

    const usersFilter = usersCollaborator.filter(
      (user) => !listDeleteUsersData.includes(user.id) && user.type.id !== 4,
    );

    if (listDeleteUsersData.length === 0 && usersFilter.length === 0) {
      history.push({
        pathname: '/menu/contas',
        state: { from: '/menu/group-customer' },
      });
    }

    setUsersCollaborator([...usersFilter, ...listDeleteUsersData]);
    setSum(0);
    setRes(data);
  };

  const addUser = (user) => {
    user.id = uid();

    const userDuplicate = findDuplicates([
      formatUserTypes(user, listActive),
      ...usersCollaborator,
    ]);
    setUsersCollaborator(userDuplicate);
    setType(false);
  };
  const addUsers = (list) => {
    const listUser = list.map((user) => {
      const formatUser = {
        id: uid(),
        ...user,
      };

      return formatUserTypes(formatUser, listActive);
    });
    const userDuplicate = findDuplicates([...listUser, ...usersCollaborator]);
    setUsersCollaborator(userDuplicate);
  };

  const editRow = (user) => {
    setType('edit');
    setEditing(true);
    setCurrentUser(user);
  };

  const deleteUser = (id) => {
    setEditing(false);
    setUsersCollaborator(
      usersCollaborator
        .filter((user) => user.id !== id)
        .filter((item) => item.id !== id && formatUserTypes(item, listActive)),
    );
  };
  const deleteUsers = () => {
    setEditing(false);
    setUsersCollaborator(
      usersCollaborator.filter((user) => !listDeleteUser.includes(user.id)),
    );
    setListDeleteUser([]);
  };

  const updateUser = (id, updatedUser) => {
    setType(false);
    setEditing(false);
    setCurrentUser(initialFormState);
    const updatedItem = usersCollaborator
      .map((user) => (user.id === id ? updatedUser : user))
      .map((item) =>
        item.id === id ? formatUserTypes(item, listActive) : item,
      );
    const userDuplicate = findDuplicates(updatedItem);
    setUsersCollaborator(userDuplicate);
  };
  const toggleSelectUsers = (itemId) => {
    if (listDeleteUser.includes(itemId)) {
      setListDeleteUser(listDeleteUser.filter((id) => id !== itemId));
    } else {
      setListDeleteUser([...listDeleteUser, itemId]);
    }
  };
  const selectAllUsers = () => {
    const ids = usersCollaborator.map((user) => user.id);

    if (firstClick) {
      setListDeleteUser([]);
      setListDeleteUser(ids);
    } else {
      setListDeleteUser([]);
    }

    // Toggle the firstClick state
    setFirstClick(!firstClick);
  };
  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <ButtonGoBack backRoute={'/menu/contas'} />
      </div>
      <div className={styles['flex-row']}>
        <div className={styles.action_button}>
          <h1 className={styles.header}>Adicionar colaboradores</h1>
          <div className={styles.wrapper_actions}>
            <ButtonMenuDownload>Templates</ButtonMenuDownload>
            <button
              className={type === 'add' ? styles.button_ : styles.button}
              onClick={() => {
                setType('add');
              }}
            >
              <IconPlus
                color={type === 'add' ? 'var(--white)' : 'var(--primary-700)'}
                size={'1.1rem'}
              />
              Adicionar Colaborador
            </button>
            <button
              className={type === 'file' ? styles.button_ : styles.button}
              onClick={() => {
                setType('file');
              }}
            >
              <IconList
                color={type === 'file' ? 'var(--white)' : 'var(--primary-700)'}
                size={'1.3rem'}
              />
              Lista de Colaboradores
            </button>
          </div>
        </div>
        {type && (
          <div className={styles['flex-large']}>
            {type === 'edit' && (
              <EditUserForm
                editing={editing}
                setType={setType}
                setEditing={setEditing}
                currentUser={currentUser}
                updateUser={updateUser}
                listActive={listActive}
              />
            )}
            {type === 'add' && (
              <AddUserForm setType={setType} addUser={addUser} />
            )}
            {type === 'file' && (
              <ExcelReader addUsers={addUsers} setType={setType} />
            )}
          </div>
        )}

        <div className={styles['flex-large']}>
          <div className={styles.header_box}>
            <div className={styles.wrapper_progress}>
              {loading && (
                <ProgressBar
                  value={sum}
                  total={usersReady.length}
                  color={'#4caf50'}
                  bg={'#4c7faf'}
                />
              )}
              {!loading && (
                <ProgressBar
                  value={usersReady.length}
                  total={usersCollaborator.length}
                  color={'#4c7faf'}
                  bg={'#e0e0e0'}
                />
              )}
            </div>
          </div>
          {listDeleteUser.length > 0 && (
            <div className={styles.header_notification}>
              <h5>
                {' '}
                {listDeleteUser.length}{' '}
                {listDeleteUser.length === 1 ? 'selecionado' : 'selecionados'}
              </h5>
              <div>
                <button onClick={deleteUsers}>
                  <MdDelete color="var(--white)" />
                </button>{' '}
                |{' '}
                <button onClick={() => setListDeleteUser([])}>Cancelar</button>
              </div>
            </div>
          )}
          <div className={styles.confirm}>
            <h5>Pré-visualização</h5>
            <div className={styles.wrapper_confirm}>
              <button
                type="submit"
                className={styles.button_send}
                onClick={handleSubmit}
                disabled={loading || usersCollaborator.length === 0}
              >
                <FaRegCircleCheck color="var(--white)" />
                {loading
                  ? 'Realizando Cadastros...'
                  : `Processar Cadastros  ( ${usersReady.length} )`}
              </button>
            </div>
          </div>
          <UserTable
            selectAllUsers={selectAllUsers}
            editing={editing}
            users={usersCollaborator}
            editRow={editRow}
            deleteUser={deleteUser}
            listDeleteUser={listDeleteUser}
            toggleSelectUsers={toggleSelectUsers}
            deleteUsers={deleteUsers}
          />
        </div>
      </div>
    </div>
  );
}
