import {
  getCustomerByDocument,
  updateCustomer,
} from '../../hooks/services/Customers';
import { Customer } from './types';

export const editCustomer = async (
  customer: Customer,
  setIsNewCustomer: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<Customer | null> => {
  const { is_active, first_name, last_name, document, email, telephone } =
    customer;

  const existingUser = await getCustomerByDocument(document);
  const user = await updateCustomer({
    is_active,
    first_name,
    last_name,
    document,
    email,
    telephone,
  });

  if (user && !existingUser) {
    setIsNewCustomer(false);
  }

  return user;
};
